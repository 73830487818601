<template>
  <div class="help">
    <h2>Notes</h2>
    <div class="essentials list-notes">
      <div class="important-note" @click="showIdNotes">
        <div class="note-illustration id-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">ID's</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showPOANotes">
        <div class="note-illustration poa-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">POA's</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showPOINotes">
        <div class="note-illustration poi-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">POI's</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showRIBNotes">
        <div class="note-illustration rib-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">RIB</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showFMNotes">
        <div class="note-illustration fm-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">FM</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showPCNNotes">
        <div class="note-illustration pcn-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">PCN's & Workbaskets</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showDialerGuide">
        <div class="note-illustration dialer-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/book.svg">
          <h3 class="note-title">Dialer - Guide</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showMycaGuide">
        <div class="note-illustration myca-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/book.svg">
          <h3 class="note-title">MYCA - Guide</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showInboxGuide">
        <div class="note-illustration inbox-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/book.svg">
          <h3 class="note-title">Inbox - Guide</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showOtherToolsNotes">
        <div class="note-illustration other-guide"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/memos.svg">
          <h3 class="note-title">Impot Gouv & Doc Checker</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showQuicksignHowTo">
        <div class="note-illustration quicksign"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/howto.svg">
          <h3 class="note-title">QuickSign - How To</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showGO2HowTo">
        <div class="note-illustration go-2"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/howto.svg">
          <h3 class="note-title">GO2 - How To</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showNMOHowTo">
        <div class="note-illustration nmo"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/howto.svg">
          <h3 class="note-title">NMO - How To</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showDocImportHowTo">
        <div class="note-illustration docs-import"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/howto.svg">
          <h3 class="note-title">Doc Import - How To</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
      <div class="important-note" @click="showIVUHowTo">
        <div class="note-illustration iVu"></div>
        <div class="note-overlay"></div>
        <div class="legend">
          <img alt="Vue logo" class="menu-icon" src="../../assets/img/howto.svg">
          <h3 class="note-title">IVU - How To</h3>
          <p class="note-description">The original plateform for customer documents checking</p>
        </div>
      </div>
    </div>
    <h2>Tools</h2>
    <div class="essentials list-links">
      <div class="important-link" @click="showIDCardCalculator">
        <div class="link-illustration card-checker"></div>
        <h3 class="link-title">ID Card Checker</h3>
        <p class="link-description">Smart tools to check french ID's validity.</p>
      </div>
      <div class="important-link" @click="showJulianDateConvertor">
        <div class="link-illustration date-convertor"></div>
        <h3 class="link-title">Julian Date Convertor</h3>
        <p class="link-description">Convert julian date to gregorian format.</p>
      </div>
      <div class="important-link" @click="showIncomeCalculator">
        <div class="link-illustration income-calculator"></div>
        <h3 class="link-title">Income Calculator</h3>
        <p class="link-description">Income estimate calculator from a monthly, quarterly of half-year POI.</p>
      </div>
      <div class="important-link" @click="showAcropedia">
        <div class="link-illustration acronyms"></div>
        <h3 class="link-title">Acropedia</h3>
        <p class="link-description">All the acronyms used by GNA (EMEA).</p>
      </div>
      <div class="important-link" @click="showLanguageHelper">
        <div class="link-illustration language-helper"></div>
        <h3 class="link-title">Language Helper</h3>
        <p class="link-description">Quick words to be able to deal with foreigners.</p>
      </div>
      <div class="important-link" @click="showBlackListedBank">
        <div class="link-illustration bank-blacklist"></div>
        <h3 class="link-title">Blacklisted Bank</h3>
        <p class="link-description">American Express will reject RIB coming from these banks.</p>
      </div>
      <div class="important-link" @click="showSlackQA">
        <div class="link-illustration slack-qa"></div>
        <h3 class="link-title">Slack Q & A</h3>
        <p class="link-description">Questions / Answer From Our Team In Slack.</p>
      </div>
<!--      <div class="important-link" @click="showEmails">-->
<!--        <div class="link-illustration email-banner"></div>-->
<!--        <h3 class="link-title">Emails</h3>-->
<!--        <p class="link-description">All email you might need in your CCP career.</p>-->
<!--      </div>-->
      <div class="important-link" @click="showContact">
        <div class="link-illustration phone-banner"></div>
        <h3 class="link-title">Contacts</h3>
        <p class="link-description">All number you might need in your CCP career.</p>
      </div>

      <div class="important-link" @click="showNotes">
        <div class="link-illustration notes-banner"></div>
        <h3 class="link-title">Notes Picker</h3>
        <p class="link-description">Copy & paste the most commonly used notes in GO2.</p>
      </div>

    </div>

    <Modal :modalActive="modalActive" @close="closeModal">

      <div class="modal-content">

        <h2 class="modal-title">{{modalTitle}}</h2>

        <div class="modal-body">

          <div class="group-button">

            <div v-for="(category, index) in currentContentCategories" class="category-displayed-button" :key="category.id">

              <h4 class="cat-button" @click="showCategory(index)">{{category.name}}</h4>

              <h4 class="cat-button-mini" @click="showCategory(index)">{{category.acronym}}</h4>

            </div>

          </div>

          <div class="group-content">

            <div v-for="(category, index) in currentContentCategories" class="category-displayed" :key="category.id">

              <div v-if="index === this.currentCategoryIndex" class="subgroup">
              <img alt="Vue logo" class="menu-icon info-icon" src="../../assets/img/info.svg">
              <h4 class="acronyms-title">Acronym: {{category.acronym}}</h4>


              <h4>Checklist:</h4>
                <div class="verif-container">
                  <div v-for="(verification) in category.verifications" class="verification-displayed" :key="verification.id">
                    <p class="verif-list">{{verification}}</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <h5 class="endComment">{{currentContentComment}}</h5>

<!--        <div class="button-duo">-->
<!--          <button class="homebuttons indivbutton button-yes" @click="confirmDelete()">Yes</button>-->
<!--          <button class="homebuttons indivbutton button-no" @click="closeModal">No</button>-->
<!--        </div>-->

      </div>

    </Modal>

    <Modal :modalActive="modalPCNActive" @close="closeModalPCN">

      <div class="modal-content" style="height: auto">

        <h2 class="modal-title">{{modalTitle}}</h2>

        <div class="modal-body" style="margin-top: 0; flex-direction: column; align-items: center;">

          <h2 style="font-size: 16px; margin-left: 0 !important;">PCN Anatomy</h2>

          <div class="pcnModel">
            <div class="partOne pcnPartStruct">
              <h2 class="pcnPart">2022</h2>
              <h3>Year</h3>
            </div>
            <div class="partTwo pcnPartStruct">
              <h2 class="pcnPart">189</h2>
              <h3>Julian<br>Date</h3>
            </div>
            <div class="partThree pcnPartStruct">
              <h2 class="pcnPart">69</h2>
              <h3>Request<br>Type</h3>
            </div>
            <div class="partFour pcnPartStruct">
              <h2 class="pcnPart">00140</h2>
              <h3>Request<br>Number</h3>
            </div>
            <div class="lastPart pcnPartStruct">
              <h2 class="pcnPart">022</h2>
              <h3>Market</h3>
            </div>
          </div>

          <h2 style="font-size: 16px; margin-left: 0 !important;">Workbaskets</h2>

          <div class="workbaskets">
            <div v-for="(workbasket) in workbaskets" class="workbasket" :key="workbasket.id">
              <h3>{{workbasket.name}}</h3>
            </div>
          </div>

        </div>

      </div>

    </Modal>

    <Modal :modalActive="modalOtherWebsiteActive" @close="closeModalOther">

        <div class="modal-content" style="height: auto">

          <h2 class="modal-title">{{modalTitle}}</h2>

          <div class="modal-body" style="margin-top: 30px; flex-direction: row; width: -webkit-fill-available; justify-content: space-evenly;">

            <div class="impot-gouv-section sub-section">
              <h2 style="font-size: 16px; margin-left: 0 !important; margin-top: 0;">Impot Gouv</h2>
              <p>Get information on CM Tax Statement with document reference & tax number.</p>
            </div>
            <div class="doc-checker-section sub-section">
              <h2 style="font-size: 16px; margin-left: 0 !important; margin-top: 0;">Doc Checker</h2>
              <p>Verify MRZ Document Online.</p>
            </div>

          </div>

        </div>

    </Modal>

    <ModalFullWidth :modal-active="modalFullWidthActive" @close="closeModalFullWidth">

      <div class="modal-content" style="height: auto;">

        <h2 class="modal-title">{{modalFullWidthTitle}}</h2>

        <div class="modal-body-fullw" style="flex-direction: column;">

          <div class="dialler" v-show="diallerGuideIsShowing">

          <div class="call">

          <div class="inbound-call">
            <h3>Inbound Call</h3>
            <div class="call-step-one call-step">
              <h4>1. Je dois vous informer que cet appel peut être enregistre pour des raisons de formation et de qualité</h4>
            </div>
            <div class="call-step-two call-step">
              <h4>2. Je vais prendre votre numero de dossier s'il vous plait ?</h4>
            </div>
            <div class="call-step-three call-step">
              <h4>3. Pourrais-je avoir votre nom et prénom ?</h4>
            </div>
            <div class="call-step-four call-step">
              <h4>4. Security questions</h4>
              <p class="security-question">Full Date Of Birth</p>
              <p class="security-question">Full Place Of Birth</p>
              <p class="security-question">Full Mother Maiden Name</p>
              <p class="security-question">Full Post Code</p>
            </div>
            <div class="call-step-five call-step">
              <h4>5. Give all possible options for all kind of documents</h4>
            </div>
          </div>

          <div class="outbound-call">
            <h3>Outbound Call</h3>
            <div class="call-step-one call-step">
              <h4>1. Pourrais-je parler à ?</h4>
            </div>
            <div class="call-step-two call-step">
              <h4>2. Je dois vous informer que cet appel peut être enregistre pour des raisons de formation et de qualité</h4>
            </div>
            <div class="call-step-three call-step">
              <h4>3. Security questions</h4>
              <p class="security-question">Month Of Birth</p>
              <p class="security-question">First 2 Letters Of Place Of Birth</p>
              <p class="security-question">First 2 Letters Of Mother Maiden Name</p>
              <p class="security-question">Last 3 Digits Of Your Postcode</p>
            </div>
            <div class="call-step-four call-step">
              <h4>4. Give all possible options for all kind of documents</h4>
            </div>
          </div>

          </div>

          <div class="message">
            <h3>Vocal Message</h3>
            <h4>Société American Express bonjour, nous vous appelons au sujet d'une demande en cours. Merci de bien vouloir nous recontacter au 0147777879, bonne journée à vous, au revoir</h4>
          </div>

          <!-- Call End -->

          </div>

          <div class="acropedia" v-show="acropediaIsShowing">

            <div v-for="(acronym, key) in acronyms" class="acronyms-displayed" :key="acronym.id">

              <p class="acronym">{{acronym}}</p>

              <div class="indication">
                <p>{{(key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2')}}</p>
              </div>

<!--              <img alt="Vue logo" class="menu-icon info-icon small-on-right" src="../../assets/img/info.svg">-->

            </div>

          </div>

          <div class="blackListedBank" v-show="blackListedBankIsShowing">

            <div v-for="(bank, key) in blacklistedBank" class="blacklisted-bank-displayed" :key="bank.id">

              <p class="bank-name" @click="copyDataToClipBoard(String(bank.bankName + ' ' + bank.bic))">{{(key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2')}}</p>

              <div class="bank-info">
                <p>{{bank.bankName}}</p>
                <p>Code(s): {{String(bank.bankCode).replaceAll(',', ', ')}}</p>
                <p>BIC(s): {{String(bank.bic).replaceAll(',', ', ')}}</p>
              </div>

            </div>

          </div>

          <div class="contactList" v-show="contactIsShowing">

            <div v-for="(contact, key) in contacts" class="contacts-displayed-group" :key="contact.id">
              <p class="contact-name" @click="copyDataToClipBoard(String(contact.ext + ' ' + contact.phone + ' ' + contact.email))">{{key.charAt(0).toUpperCase() + key.slice(1)}}</p>
              <div class="contact-info">
                <p>Ext: {{contact.ext}}</p>
                <p>Phone: {{contact.phone}}</p>
                <p>Email: {{contact.email}}</p>
              </div>
            </div>

            <div v-for="(mate, key) in contactTeam" class="contacts-displayed" :key="mate.id">
              <p class="contact-name" @click="copyDataToClipBoard(mate)">{{key.charAt(0).toUpperCase() + key.slice(1)}}</p>
              <div class="contact-info">
                <p>Ext: {{mate}}</p>
              </div>
            </div>

          </div>

          <div class="notesList" v-show="notesIsShowing">



          </div>

          <div class="card-checker-module" v-show="idCardCheckerIsShowing">

            <h3 class="question">Enter ID Card Deliverance Date ?</h3>
            <input class="input-help" type="text" ref="search" v-model="deliveredAt" v-on:keyup="checkID">

            <h3 class="question">Enter ID Card Birth Date ?</h3>
            <input class="input-help" type="text" ref="search" v-model="idBirthday" v-on:keyup="checkID">

          </div>

          <div class="julian-date-convertor-module" v-show="julianDateConvertorIsShowing">

            <h3 class="question">Enter Julian Date ?</h3>
            <input class="input-help" type="text" ref="search" v-model="julianDateToConvert" v-on:keyup="convertToGregorian">

          </div>

          <div class="income-calculator-module" v-show="incomeCalculatorIsShowing">

            <h3 class="question">Enter Income Amount ?</h3>
            <input class="input-help" type="text" ref="search" v-model="deliveredAt" v-on:keyup="checkID">

          </div>

        </div>

        <!--        <div class="button-duo">-->
        <!--          <button class="homebuttons indivbutton button-yes" @click="confirmDelete()">Yes</button>-->
        <!--          <button class="homebuttons indivbutton button-no" @click="closeModal">No</button>-->
        <!--        </div>-->

      </div>

    </ModalFullWidth>

  </div>
</template>

<!--GNA - Global New Accounts System - used to decision applications for card products.-->

<script>
import Modal from "@/core/components/Modal";
import ModalFullWidth from "@/core/components/ModalFullWidth";
import acropedia from '@/data/local/acropedia.json';
import iDVerification from '@/data/local/verificationID.json';
import poaVerification from '@/data/local/verificationPOA.json';
import poiVerification from '@/data/local/verificationPOI.json';
import ribVerification from '@/data/local/verificationRIB.json';
import fmVerification from '@/data/local/verificationFM.json';
import workbasketList from '@/data/local/workbaskets.json';
import prohibitedBank from '@/data/local/prohibitedBank.json';
import contact from '@/data/local/contact.json';
import contactTeam from '@/data/local/contactTeam.json';

export default {
  name: 'HelpView',
  components: {
    Modal,
    ModalFullWidth
  },
  data(){
    return{
      modalTitle: "",
      modalActive: false,
      modalFullWidthTitle: "",
      modalFullWidthActive: false,
      modalPCNActive: false,
      modalOtherWebsiteActive: false,
      acronyms: {},
      contacts: {},
      contactTeam: {},
      blacklistedBank:{},
      currentContentCategories: {},
      currentContentComment: "",
      currentCategoryIndex: 0,
      workbaskets: {},

      diallerGuideIsShowing: false,
      mycaGuideIsShowing: false,
      inboxGuideIsShowing: false,

      quickSignHowToIsShowing: false,
      goTwoHowToIsShowing: false,
      nmoHowToIsShowing: false,
      docImportHowToIsShowing: false,
      iVuHowToIsShowing: false,

      idCardCheckerIsShowing: false,
      julianDateConvertorIsShowing: false,
      incomeCalculatorIsShowing: false,
      acropediaIsShowing: false,
      languageHelperIsShowing: false,
      blackListedBankIsShowing: false,
      slackQAndAIsShowing: false,
      contactIsShowing: false,
      notesIsShowing: false
    }
  },
  methods: {
    showModal(){

      if(!this.modalActive){
        this.currentCategoryIndex = 0;
        this.modalActive = true;
      }

    },
    closeModal(){

      if(this.modalActive){
        this.modalActive = false;
      }

    },
    showModalFullWidth(){

      if(!this.modalFullWidthActive){
        this.modalFullWidthActive = true;
      }

    },
    closeModalFullWidth(){

      if(this.modalFullWidthActive){
        this.modalFullWidthActive = false;
      }

    },
    showModalPCN(){

      if(!this.modalPCNActive){
        this.modalPCNActive = true;
      }

    },
    closeModalPCN(){

      if(this.modalPCNActive){
        this.modalPCNActive = false;
      }

    },
    showModalOther(){

      if(!this.modalOtherWebsiteActive){
        this.modalOtherWebsiteActive = true;
      }

    },
    closeModalOther(){

      if(this.modalOtherWebsiteActive){
        this.modalOtherWebsiteActive = false;
      }

    },
    showIdNotes(){
      this.modalTitle = "ID Verification"
      this.currentContentCategories = iDVerification.content.categories
      this.currentContentComment = iDVerification.content.comment
      this.showModal()
    },
    showPOANotes(){
      this.modalTitle = "POA Verification"
      this.currentContentCategories = poaVerification.content.categories
      this.currentContentComment = poaVerification.content.comment
      this.showModal()
    },
    showPOINotes(){
      this.modalTitle = "POI Verification"
      this.currentContentCategories = poiVerification.content.categories
      this.currentContentComment = poiVerification.content.comment
      this.showModal()
    },
    showRIBNotes(){
      this.modalTitle = "RIB Verification"
      this.currentContentCategories = ribVerification.content.categories
      this.currentContentComment = ribVerification.content.comment
      this.showModal()
    },
    showFMNotes(){
      this.modalTitle = "FM Verification"
      this.currentContentCategories = fmVerification.content.categories
      this.currentContentComment = fmVerification.content.comment
      this.showModal()
    },
    showPCNNotes(){
      this.modalTitle = "PCN & Workbaskets"
      this.workbaskets = workbasketList.list
      this.showModalPCN()
    },
    showOtherToolsNotes(){
      this.modalTitle = "Impot Gouv & Doc Checker"
      this.showModalOther()
    },
    showDialerGuide(){

      this.diallerGuideIsShowing = true
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Dialer Guide"
      this.showModalFullWidth()

    },
    showMycaGuide(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = true
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "MYCA Guide"
      this.showModalFullWidth()
    },
    showInboxGuide(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = true

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Inbox Guide"
      this.showModalFullWidth()
    },
    showQuicksignHowTo(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = true
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Quicksign - How To"
      this.showModalFullWidth()
    },
    showNMOHowTo(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = true
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "NMO - How To"
      this.showModalFullWidth()
    },
    showGO2HowTo(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = true
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "GO2 - How To"
      this.showModalFullWidth()
    },
    showIVUHowTo(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = true

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "IVU - How To"
      this.showModalFullWidth()
    },
    showDocImportHowTo(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = true
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Docs Import - How To"
      this.showModalFullWidth()
    },
    showIDCardCalculator(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = true
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "ID Card Checker"
      this.showModalFullWidth()
    },
    showJulianDateConvertor(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = true
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Julian Date Convertor"
      this.showModalFullWidth()
    },
    showIncomeCalculator(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = true
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Income Calculator"
      this.showModalFullWidth()
    },
    showAcropedia(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = true
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Acropedia"
      this.acronyms = acropedia.acronyms

      this.showModalFullWidth()
    },
    showLanguageHelper(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = true
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Language Helper"
      this.showModalFullWidth()
    },
    showBlackListedBank(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = true
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.blacklistedBank = prohibitedBank.prohibitedBanks;

      this.modalFullWidthTitle = "Blacklisted Bank"
      this.showModalFullWidth()
    },
    showSlackQA(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = true
      this.contactIsShowing = false
      this.notesIsShowing = false

      this.modalFullWidthTitle = "Slack Q & A"
      this.showModalFullWidth()
    },
    showContact(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = true
      this.notesIsShowing = false

      this.contacts = contact.contact;
      this.contactTeam = contactTeam.contactTeam;

      this.modalFullWidthTitle = "Contacts"
      this.showModalFullWidth()
    },
    showNotes(){

      this.diallerGuideIsShowing = false
      this.mycaGuideIsShowing = false
      this.inboxGuideIsShowing = false

      this.quickSignHowToIsShowing = false
      this.goTwoHowToIsShowing = false
      this.nmoHowToIsShowing = false
      this.docImportHowToIsShowing = false
      this.iVuHowToIsShowing = false

      this.idCardCheckerIsShowing = false
      this.julianDateConvertorIsShowing = false
      this.incomeCalculatorIsShowing = false
      this.acropediaIsShowing = false
      this.languageHelperIsShowing = false
      this.blackListedBankIsShowing = false
      this.slackQAndAIsShowing = false
      this.contactIsShowing = false
      this.notesIsShowing = true

      this.contacts = contact.contact;
      this.contactTeam = contactTeam.contactTeam;

      this.modalFullWidthTitle = "Notes Picker"
      this.showModalFullWidth()
    },
    showCategory(index){
      this.currentCategoryIndex = index
    },
    copyDataToClipBoard(data){
      navigator.clipboard.writeText(data);
    }
  }
}
</script>

<style>

.help{
  margin-top: 100px;
  text-align: left;
}

.help h2{
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.note-illustration{
  width: 180px;
  height: 180px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.id-guide{
  background-image: url('~@/assets/img/id.jpg');
}

.poa-guide{
  background-image: url('~@/assets/img/poa.jpg');
}

.poi-guide{
  background-image: url('~@/assets/img/poi.jpg');
}

.rib-guide{
  background-image: url('~@/assets/img/rib.jpg');
}

.fm-guide{
  background-image: url('~@/assets/img/facematch.jpg');
}

.pcn-guide{
  background-image: url('~@/assets/img/workbasket.jpg');
}

.dialer-guide{
  background-image: url('~@/assets/img/diallercall.jpg');
}

.myca-guide{
  background-image: url('~@/assets/img/mycacouple.jpg');
}

.inbox-guide{
  background-image: url('~@/assets/img/inboxmail.jpg');
}

.other-guide{
  background-image: url('~@/assets/img/others.jpg');
}

.card-checker{
  background-image: url('~@/assets/img/cardcheckers.jpg');
}

.date-convertor{
  background-image: url('~@/assets/img/juliandateconvertor.jpg');
}

.income-calculator{
  background-image: url('~@/assets/img/incomecalculator.jpg');
}

.acronyms{
  background-image: url('~@/assets/img/acronyms.jpg');
}

.language-helper{
  background-image: url('~@/assets/img/langagehelper.jpg');
}

.bank-blacklist{
  background-image: url('~@/assets/img/blacklisted-bank.jpg');
}

.slack-qa{
  background-image: url('~@/assets/img/slackqanda.jpg');
}

.email-banner{
  background-image: url('~@/assets/img/email.jpg');
}

.phone-banner{
  background-image: url('~@/assets/img/numbers.jpg');
}

.notes-banner{
  background-image: url('~@/assets/img/notespicker.jpg');
}

.list-notes{
  display: grid;
  padding: 20px;
  /*grid-template-areas: "a a a a a a";*/
  grid-template-columns: max-content max-content max-content max-content max-content;
  gap: 50px;
}

.important-note{
  max-width: 180px;
  cursor: pointer;
  background: #0b1149;
  /*padding: 20px;*/
  bottom: 0;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  position: relative;
}

.important-note:hover{
  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  bottom: 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.note-title{
  margin-top: 15px;
  text-align: center;
}

.note-description{
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  display: none;
}

.legend{
  position: absolute;
  top: 0;
  margin-left: 10px;
  margin-right: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.note-overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #032436;
  opacity: 0.6;
}

@media screen and (max-width: 1360px) {
  .list-notes{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content max-content max-content;
    /*gap: 20px;*/
  }
}

@media screen and (max-width: 1100px) {
  .list-notes{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content max-content;
    /*gap: 20px;*/
  }
}

@media screen and (max-width: 875px) {
  .list-notes{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content;
    /*gap: 20px;*/
  }
}

@media screen and (max-width: 630px) {
  .list-notes{
    gap: 20px;
  }
  .important-note{
    max-width: 120px;
  }
  .note-illustration{
    width: 120px;
    height: 120px;
  }
  .note-description{
    font-size: 10px;
    font-weight: bold;
  }
  .note-title{
    font-size: 14px;
  }
}

.modal-title{
  text-align: center;
}

.modal-body{
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
}

.modal-body-fullw{
  width: -webkit-fill-available;
  margin-top: 50px;
}

.dialler{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  text-align: center;
}

.cat-button, .cat-button-mini{
  width: max-content;
  padding: 5px 10px;
  background: #0b1149;
  cursor: pointer;
  /*border: 2px solid white;*/
  /*border-radius: 25px;*/
  margin: 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.cat-button:hover, .cat-button-mini:hover{
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.category-displayed h4{
  margin-top: 0px;
}

.verif-list{
  font-weight: bold;
  font-size: 12px;
  margin: 0
}

.verification-displayed{
  width: max-content;
  border: 1px solid white;
  padding: 10px 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.verification-displayed:hover{
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.subgroup{
  text-align: center;
  padding: 0 20px 15px;
}

.endComment{
  text-align: center;
  margin-top: 35px;
}

.group-content{
  width: fill-available;
  border: 1px solid white;
}

.verif-container{
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  justify-content: space-around;
  align-items: center;
  row-gap: 20px;
  cursor: pointer;
  column-gap: 20px;
}

@media screen and (max-width: 750px){
  .modal-body{
    flex-direction: column;
  }
  .group-button{
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 550px){
  .cat-button{
    display: flex;
  }
  .cat-button-mini{
    display: none;
  }
}

@media screen and (max-width: 550px){
  .cat-button-mini{
    display: flex;
  }
  .cat-button{
    display: none;
  }
}

.info-icon{
  margin-top: 10px;
  cursor: pointer;
}

.acronyms-title{
  margin-bottom: 10px;
}

.pcnModel{
  display: flex;
  gap: 15px;
}

.pcnPart{
  border-bottom: 1px solid #0b1149;
  margin-left: 0 !important;
  margin-top: 0 !important;
  text-align: center !important;
  border-left: 1px solid #0b1149;
  border-right: 1px solid #0b1149;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.pcnPart:hover{
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.pcnPartStruct{
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*align-items: center;*/
}

.pcnPartStruct h3{
  font-size: 12px;
  text-align: center;
}

.workbasket{
  display: grid;
  grid-template-columns: auto auto auto auto;
  font-size: 10px;
  text-align: center;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  padding: 5px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.workbasket:hover {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.workbaskets {
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.sub-section {
  text-align: center;
  max-width: 150px;
}

.call {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.message {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.call-step h4, .message h4 {
  font-size: 12px;
}

.security-question {
  margin: 0;
  font-size: 12px;
}

.acropedia{
  display: grid;
  grid-template-areas: "a a a a a a a a a a";
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.acronyms-displayed{
  position: relative;
  flex-grow: 1;
  width: 100px;
  text-align: center;
  border: 1px solid white;
  margin: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.acronyms-displayed:hover{
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.acronyms-displayed p {
  margin: 5px;
  font-size: 12px;
  font-weight: bold;
}

.contactList, .blackListedBank{
  display: grid;
  grid-template-areas: "a a a a a a a a a a";
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  margin-top: -40px;
}

.contacts-displayed, .contacts-displayed-group, .blacklisted-bank-displayed{
  position: relative;
  border: 1px solid white;
  width: 80px;
  text-align: center;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.contacts-displayed p, .contacts-displayed-group p, .blacklisted-bank-displayed p{
  font-size: 12px;
  margin: 5px;
  font-weight: bold;
}

.contacts-displayed-group, .blacklisted-bank-displayed{
  height: 60px;
}

.contacts-displayed:hover, .contacts-displayed-group:hover, .blacklisted-bank-displayed:hover{
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.indication{
  position: absolute;
  background-color: #0b1149cc;
  backdrop-filter: blur(55px);
  bottom: 50px;
  padding: 0 15px;
  border-radius: 11px;
  left: -50px;
  max-width: 200px;
  width: -webkit-max-content;
  width: max-content;
  opacity: 0;
  transform: scale(0);
  transition: all .2s ease-in-out;
  z-index: 1;
}

.acronym:hover + .indication {
  transform: scale(1);
  opacity: 1;
  transition: all .2s ease-in-out;
}

.indication p, .contact-info p, .bank-info p {
  font-size: 16px;
}

.contact-info, .bank-info{
  position: absolute;
  background-color: #0b1149cc;
  backdrop-filter: blur(55px);
  bottom: 70px;
  padding: 0 15px;
  border-radius: 11px;
  left: -50px;
  max-width: 300px;
  width: -webkit-max-content;
  width: max-content;
  opacity: 0;
  transform: scale(0);
  transition: all .2s ease-in-out;
  z-index: 2;
}

.contact-name:hover + .contact-info, .bank-name:hover + .bank-info{
  transform: scale(1);
  opacity: 1;
  transition: all .2s ease-in-out;
}

.input-help{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: transparent;
  border: 2px solid white;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}

.modal-content{
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 1150px){
  .contactList, .acropedia, .blackListedBank{
    width: -webkit-fill-available;
    grid-template-areas: none;
    grid-template-columns: repeat(auto-fill, 100px);
    gap: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  /*.help{*/
  /*  margin-right: 20px;*/
  /*}*/
  .list-notes{
    grid-template-columns: 1fr 1fr;
  }
  .important-note{
    max-width: none;
  }
  .note-illustration{
    width: -webkit-fill-available;
    height: auto;
    aspect-ratio: 1;
  }
}

/*.small-on-right{*/
/*  width: 10px;*/
/*  position: absolute;*/
/*}*/

</style>