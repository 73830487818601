<template>
  <div class="login">
    <div class="login-struct">
      <img alt="logo-amex" class="logo-amex" src="../assets/img/axp-logo.svg">
      <div class="app-title">
        <div class="app-logo">
          <img alt="logo" class="logo" src="../assets/img/logo.png">
        </div>
        <div class="title">
          <h2>Global New Account</h2>
          <h3>French Department</h3>
        </div>
      </div>
      <div class="amex-inputs">
        <input class="text-fields" type="text" placeholder="Account" ref="account" v-model="account">
        <input class="text-fields" type="text" placeholder="Email" ref="email" v-model="email">
        <input class="text-fields" type="password" placeholder="Password" ref="password" v-model="password">
        <div class="button-layout">
          <AdaptableButton text="Login" color="#0049ff" central=false @click="login"/>
        </div>
      </div>
    </div>
    <transition name="toast">
      <Toast v-if="showToast" :message="message"/>
    </transition>
  </div>
</template>

<script>
import AdaptableButton from '../core/components/AdaptableButton.vue';
import Toast from '../core/components/Toast.vue';
import { firebaseAuth } from '@/main';
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  components: {
    AdaptableButton,
    Toast
  },
  data() {
    return{
      account: "",
      email: "",
      password:"",
      showToast: false,
      message: "Something went wrong."
    }
  },
  methods: {
    login(){

      const that = this;

      if(this.account === "Amex1850") {

        signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
            .then(data => console.log(data))
            .catch(err => {
              console.log(err.message)
              that.triggerToast("Wrong credentials")
            });

      } else {
        this.triggerToast("Wrong account number")
      }
    },
    triggerToast(message){

      this.showToast = true;

      this.message = message

      setTimeout(() => this.showToast = false, 4000)

    }
  }
}

</script>

<style scoped>

.logo-amex{
  width: 100px;
}

.logo{
  max-width: 50px;
}

.login{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-struct{

  max-width: 800px;
  width: 80%;
  /*background-color: #0061b6;*/
  background-color: #02182b;
  border-radius: 5px;
  min-height: 200px;
  padding: 20px 20px 30px;
  box-shadow: 0 3px 30px rgba(130, 107, 107, 0.3)

}

.amex-inputs{
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  margin-top: 20px;
}

.text-fields{
  background-color: #d6eaff;
  border: none;
  outline: none;
  color: #15243c;
  border-radius: 5px;
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.title{
  padding-top: 10px;
}

.app-title{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 20px;
}

</style>