<template>
  <div class="KYTM">
    <h1>Coming Soon</h1>
  </div>
</template>

<script>
export default {
  name: 'KYTMView'
}
</script>

<style>
.KYTM{
  margin-top: 100px;
  text-align: center;
}

.KYTM h2{
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

</style>