
import { Options, Vue } from 'vue-class-component';
import { firebaseAuth } from '@/main';
import { signOut } from "firebase/auth";
import HelloWorld from '@/core/components/HelloWorld.vue'; // @ is an alias to /src
import HomeView from '@/core/components/HomeView.vue';
import ProcessesView from '@/core/components/ProcessesView.vue';
import KYTMView from '@/core/components/KYTMView.vue';
import SimulatorView from '@/core/components/SimulatorView.vue';
import HelpView from "@/core/components/HelpView.vue";

@Options({
  components: {
    HelpView,
    HelloWorld,
    HomeView,
    ProcessesView,
    KYTMView,
    SimulatorView,
  },
  methods: {
    showMenu(){
      this.showAccountMenu = !this.showAccountMenu
    },
    showHomeView(){
      this.showAccountMenu = false;
      this.showProcesses = false;
      this.showReminders = false;
      this.showMemos = false;
      this.showTools = false;
      this.showSimulator = false;
      this.showKYTM = false;
      this.showHome = true;
    },
    showProcessesView(){
      this.showAccountMenu = false;
      this.showHome = false;
      this.showReminders = false;
      this.showMemos = false;
      this.showTools = false;
      this.showSimulator = false;
      this.showKYTM = false;
      this.showProcesses = true;
    },
    showRemindersView(){
      this.showAccountMenu = false;
      this.showHome = false;
      this.showProcesses = false;
      this.showMemos = false;
      this.showTools = false;
      this.showSimulator = false;
      this.showKYTM = false;
      this.showReminders = true;
    },
    showMemosView(){
      this.showAccountMenu = false;
      this.showHome = false;
      this.showProcesses = false;
      this.showTools = false;
      this.showSimulator = false;
      this.showKYTM = false;
      this.showReminders = false;
      this.showMemos = true;
    },
    showToolsView(){
      this.showAccountMenu = false;
      this.showHome = false;
      this.showProcesses = false;
      this.showSimulator = false;
      this.showKYTM = false;
      this.showReminders = false;
      this.showMemos = false;
      this.showTools = true;
    },
    showSimulatorView(){
      this.showAccountMenu = false;
      this.showHome = false;
      this.showProcesses = false;
      this.showKYTM = false;
      this.showReminders = false;
      this.showMemos = false;
      this.showTools = false;
      this.showSimulator = true;
    },
    showKYTMView(){
      // this.showAccountMenu = false;
      // this.showHome = false;
      // this.showProcesses = false;
      // this.showReminders = false;
      // this.showMemos = false;
      // this.showTools = false;
      // this.showSimulator = false;
      // this.showKYTM = true;
      window.open("https://kytm.supralata.com", "_blank");
    },
    logout(){
      signOut(firebaseAuth);
    }
  },
  data(){
    return{
      showAccountMenu: false,
      showHome: true,
      showProcesses: false,
      showReminders: false,
      showMemos: false,
      showTools: false,
      showSimulator: false,
      showKYTM: false,
    }
  }
})
export default class Home extends Vue {}
