<template>
  <div class="simulator">
    <h1>Generate new applications</h1>
    <div class="option button-banner">
      <!-- 69 -->
      <div class="option-background website-option"></div>
      <div class="option-overlay"></div>
      <div class="option-title">
        <h3>Generate new website app</h3>
      </div>
    </div>
    <div class="option button-banner">
      <!-- 88 -->
      <div class="option-background paper-option"></div>
      <div class="option-overlay"></div>
      <div class="option-title">
        <h3>Generate new paper app</h3>
      </div>
    </div>
    <div class="option button-banner">
      <!-- EC -->
      <div class="option-background myca-option"></div>
      <div class="option-overlay"></div>
      <div class="option-title">
        <h3>Generate new myca app</h3>
      </div>
    </div>
    <div class="option button-banner">
      <!-- AZ -->
      <div class="option-background amazon-option"></div>
      <div class="option-overlay"></div>
      <div class="option-title">
        <h3>Generate new amazon app</h3>
      </div>
    </div>
    <div class="option button-banner">
      <div class="option-background go3-option"></div>
      <div class="option-overlay"></div>
      <div class="option-title">
        <h4>Try General Onboarding Orchestration 3 (GO3)</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimulatorView'
}
</script>

<style>
.simulator{
  margin-top: 100px;
  text-align: center;
}

.simulator h2{
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.option{
  background-color: #0b1149;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  margin: 20px auto 0 auto;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid white;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.option:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.option h2{
  margin: 0;
  z-index: 2;
}

.option-background{
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  filter: blur(13px);
  background-image: url('~@/assets/img/application.jpg');
}

.option-overlay{
  position: absolute;
  background-color: #032436;
  opacity: 0.8;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.option-title{
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>