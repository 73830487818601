<template>
  <div class="processes">

    <Transition>
      <div class="stepOne step" v-show="showMenu">
        <h2>What would you like to do right now ?</h2>
        <div class="processesChoice">
          <div class="process" @click="showQuicksignProcess">
            <div class="process-background application"></div>
            <div class="process-overlay"></div>
            <div class="process-title">
              <img alt="Vue logo" class="menu-icon" src="../../assets/img/documents.svg">
              <h3 class="process-title">Quicksign / NMO</h3>
            </div>
          </div>
          <div class="process" @click="showInboxProcess">
            <div class="process-background inbox"></div>
            <div class="process-overlay"></div>
            <div class="process-title">
            <img alt="Vue logo" class="menu-icon" src="../../assets/img/mail.svg">
            <h3 class="process-title">Inbox</h3>
            </div>
          </div>
          <div class="process" @click="showDialerProcess">
            <div class="process-background dialer"></div>
            <div class="process-overlay"></div>
            <div class="process-title">
              <img alt="Vue logo" class="menu-icon" src="../../assets/img/call.svg">
              <h3 class="process-title">Dialer</h3>
            </div>
          </div>
          <div class="process" @click="showMycaProcess">
            <div class="process-background myca"></div>
            <div class="process-overlay"></div>
            <div class="process-title">
              <img alt="Vue logo" class="menu-icon" src="../../assets/img/myca.svg">
              <h3 class="process-title">MYCA</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepTwoQuickSign step" v-show="showQuicksignMenu">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/documents.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Quicksign / NMO</h2>
        <div class="firstChoiceStruct" style="margin-top: 0; flex-direction: column; gap: 0">
          <div class="option button-banner" @click="showQuicksignTrackerModule">
            <!-- 69 -->
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>QS / NMO Notes Tracker</h3>
            </div>
          </div>
          <div class="option button-banner" @click="showQuicksignNotesMakerModule">
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>QS / NMO Notes Maker</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeQuickSignTracker step" v-show="showQuicksignTracker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToQuickSignMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/documents.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Quicksign / NMO Tracker</h2>
        <p style="font-weight: bold; font-size: 12px; margin: 10px 10px 0;">{{appCompleted}}/{{numberOfAppsToComplete}}</p>
        <div class="app-to-complete-header">
          <span v-for="(app, index) in numberOfAppsToComplete" class="app-bullet" :class="{'app-bullet-active': index < appCompleted}" :key="app.id">
            <p style="display: none">{{app}}</p>
          </span>
        </div>
        <div class="tracker-wrapper">
          <img alt="Vue logo" class="less-button-icon" style="margin-top: 20px;" src="../../assets/img/less.svg" @click="lessTime">
          <svg class="tracker-prog" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="tracker-svg">
              <circle class="progression" r="8" cx="12" cy="12"/>
            </g>
          </svg>
          <img alt="Vue logo" class="plus-button-icon" style="margin-top: 20px;" src="../../assets/img/plus.svg" @click="moreTime">
          <div class="set-end-time" v-if="!countdownStarted">
<!--            <p v-if="endHour>9">Until {{endHour}}</p>-->
<!--            <p v-if="endHour<10">Until 0{{endHour}}</p>-->
            <p v-if="endHour>9 && endMinute>9">Ends at<br>{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour>9 && endMinute<10">Ends at<br>{{endHour}}:0{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute>9">Ends at<br>0{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute<10">Ends at<br>0{{endHour}}:0{{endMinute}}:00</p>
<!--            show hours-->
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond>9">Time left: {{countdownHours}}:{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond<10">Time left: {{countdownHours}}:{{countdownMinute}}:0{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond>9">Time left: {{countdownHours}}:0{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond<10">Time left: {{countdownHours}}:0{{countdownMinute}}:0{{countdownSecond}}</p>
<!--            show only minutes & seconds-->
            <p v-if="countdownHours<1 && countdownSecond>9">Time left: {{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours<1 && countdownSecond<10">Time left: {{countdownMinute}}:0{{countdownSecond}}</p>
          </div>
          <div class="countdown-wrapper" v-if="countdownStarted">
            <h3 v-if="countdownMinute > 9 && countdownSecond > 9">{{countdownMinute}}:{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute > 9 && countdownSecond < 10">{{countdownMinute}}:0{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute < 10 && countdownSecond < 10">0{{countdownMinute}}:0{{countdownSecond}}</h3>
          </div>
        </div>
        <div class="button-command">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/stop.svg" @click="stopAndReset">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/play.svg" @click="start(countdownMinute)">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/pause.svg" @click="pause">
        </div>
        <div class="apps-command">
          <div class="next-app-button" @click="previousApp">
            <h3>Previous</h3>
          </div>
          <div class="next-app-button" @click="nextApp">
            <h3>Next</h3>
          </div>
        </div>
<!--        <input type="text" ref="howLong" v-model="activityLength" v-on:keyup="confirmActivityLength">-->
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeQuickSignNotesMaker step" v-show="showQuicksignNotesMaker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToQuickSignMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/documents.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">QS / NMO Notes Maker</h2>
        <div class="questions-group-wrapper">

          <Transition>
            <div class="nmo-question-step nmo-question-step-one" v-show="nmoNoteMakersStepOne">
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" style="opacity: 0.2" @click="showNMOPreviousStep">
              <div class="myca-questions">
                <div class="note-maker-question-wrapper">
                  <h3>Is the ID document provided compliant ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idComp === true}" @click="putIDComp">
                      <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                          <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': idComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                        </g>
                      </svg>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idComp === false && idComp !== null}" @click="putIDNotComp">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                        <path class="cross" :class="{'cross-selected': idComp === false && idComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="note-maker-question-wrapper">
                  <h3>What type of ID document was provided ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.nic === true}" @click="viaNIC">
                      <p>NIC</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.pp === true}" @click="viaPP()">
                      <p>PP</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.rp === true}" @click="viaRP()">
                      <p>RP</p>
                    </div>
                  </div>
                </div>
              </div>
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/next.svg" @click="showNMONextStep">
            </div>
          </Transition>

          <Transition>
            <div class="nmo-question-step nmo-question-step-two" v-show="nmoNoteMakersStepTwo">
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" @click="showNMOPreviousStep">
              <div class="myca-questions">
                <div class="note-maker-question-wrapper">
                  <h3>Is the POA document provided compliant ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaComp === true}" @click="putPOAComp">
                      <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                          <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': poaComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                        </g>
                      </svg>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaComp === false && poaComp !== null}" @click="putPOANotComp">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                        <path class="cross" :class="{'cross-selected': poaComp === false && poaComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="note-maker-question-wrapper">
                  <h3>What type of POA document was provided ?</h3>
                  <div class="group-choice-large">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ub === true}" @click="poaCompViaUB()">
                      <p>UB</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.pt === true}" @click="poaCompViaPT()">
                      <p>PT</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ts === true}" @click="poaCompViaTS()">
                      <p>TS</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.bs === true}" @click="poaCompViaBS()">
                      <p>BS</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ps === true}" @click="poaCompViaPS()">
                      <p>PS</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.rr === true}" @click="poaCompViaRR()">
                      <p>RR</p>
                    </div>
                  </div>
                </div>
              </div>
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/next.svg" @click="showNMONextStep">
            </div>
          </Transition>

          <Transition>
            <div class="nmo-question-step nmo-question-step-three" v-show="nmoNoteMakersStepThree">
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" @click="showNMOPreviousStep">
              <div class="myca-questions">
                <div class="note-maker-question-wrapper">
                  <h3>Is the POI document provided compliant ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poiComp === true}" @click="putPOIComp">
                      <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                          <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': poiComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                        </g>
                      </svg>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poiComp === false && poiComp !== null}" @click="putPOINotComp">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                        <path class="cross" :class="{'cross-selected': poiComp === false && poiComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="note-maker-question-wrapper">
                  <h3>What type of POI document was provided ?</h3>
                  <div class="group-choice-large">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poiUsed.ts === true}" @click="poiCompViaTS()">
                      <p>TS</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poiUsed.bs === true}" @click="poiCompViaBS()">
                      <p>BS</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': poiUsed.ps === true}" @click="poiCompViaPS()">
                      <p>PS</p>
                    </div>
                  </div>
                </div>
              </div>
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/next.svg" @click="showNMONextStep">
            </div>
          </Transition>

          <Transition>
            <div class="nmo-question-step nmo-question-step-four" v-show="nmoNoteMakersStepFour">
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" @click="showNMOPreviousStep">
              <div class="myca-questions">
                <div class="note-maker-question-wrapper">
                  <h3>Is the RIB provided compliant ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': ribComp === true}" @click="putRIBComp">
                      <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                          <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': ribComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                        </g>
                      </svg>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': ribComp === false && ribComp !== null}" @click="putRIBNotComp">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                        <path class="cross" :class="{'cross-selected': ribComp === false && ribComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="note-maker-question-wrapper">
                  <h3>Is the Facematch compliant ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': fmComp === true}" @click="putFMComp">
                      <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                          <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': fmComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                        </g>
                      </svg>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': fmComp === false && fmComp !== null}" @click="putFMNotComp">
                      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                        <path class="cross" :class="{'cross-selected': fmComp === false && fmComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

        </div>

        <div class="note-generated-wrapper">
          <!--          <h4 class="note-generated">MYCA &#60; </h4>-->
          <h4 class="note-generated" @click="copyNoteGenerated()">{{noteGenerated}}</h4>
          <p>Click the note to copy to clipboard</p>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepTwoInbox step" v-show="showInboxMenu">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/mail.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Inbox</h2>
        <div class="firstChoiceStruct" style="margin-top: 0; flex-direction: column; gap: 0">
          <div class="option button-banner"  @click="showInboxTrackerModule">
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>IB Notes Tracker</h3>
            </div>
          </div>
          <div class="option button-banner" @click="showInboxNotesMakerModule">
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>IB Notes Maker</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeInboxTracker step" v-show="showInboxTracker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToInboxMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/mail.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Inbox Tracker</h2>
        <p style="font-weight: bold; font-size: 12px; margin: 10px 10px 0;">{{appCompleted}}/{{numberOfAppsToComplete}}</p>
        <div class="app-to-complete-header">
          <span v-for="(app, index) in numberOfAppsToComplete" class="app-bullet" :class="{'app-bullet-active': index < appCompleted}" :key="app.id">
            <p style="display: none">{{app}}</p>
          </span>
        </div>
        <div class="tracker-wrapper">
          <img alt="Vue logo" class="less-button-icon" style="margin-top: 20px;" src="../../assets/img/less.svg" @click="lessTime">
          <svg class="tracker-prog" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="tracker-svg">
              <circle class="progression" r="8" cx="12" cy="12"/>
            </g>
          </svg>
          <img alt="Vue logo" class="plus-button-icon" style="margin-top: 20px;" src="../../assets/img/plus.svg" @click="moreTime">
          <div class="set-end-time" v-if="!countdownStarted">
            <!--            <p v-if="endHour>9">Until {{endHour}}</p>-->
            <!--            <p v-if="endHour<10">Until 0{{endHour}}</p>-->
            <p v-if="endHour>9 && endMinute>9">Ends at<br>{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour>9 && endMinute<10">Ends at<br>{{endHour}}:0{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute>9">Ends at<br>0{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute<10">Ends at<br>0{{endHour}}:0{{endMinute}}:00</p>
            <!--            show hours-->
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond>9">Time left: {{countdownHours}}:{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond<10">Time left: {{countdownHours}}:{{countdownMinute}}:0{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond>9">Time left: {{countdownHours}}:0{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond<10">Time left: {{countdownHours}}:0{{countdownMinute}}:0{{countdownSecond}}</p>
            <!--            show only minutes & seconds-->
            <p v-if="countdownHours<1 && countdownSecond>9">Time left: {{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours<1 && countdownSecond<10">Time left: {{countdownMinute}}:0{{countdownSecond}}</p>
          </div>
          <div class="countdown-wrapper" v-if="countdownStarted">
            <h3 v-if="countdownMinute > 9 && countdownSecond > 9">{{countdownMinute}}:{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute > 9 && countdownSecond < 10">{{countdownMinute}}:0{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute < 10 && countdownSecond < 10">0{{countdownMinute}}:0{{countdownSecond}}</h3>
          </div>
        </div>
        <div class="button-command">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/stop.svg" @click="stopAndReset">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/play.svg" @click="start(countdownMinute)">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/pause.svg" @click="pause">
        </div>
        <div class="apps-command">
          <div class="next-app-button" @click="previousApp">
            <h3>Previous</h3>
          </div>
          <div class="next-app-button" @click="nextApp">
            <h3>Next</h3>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeInboxNotesMaker step" v-show="showInboxNotesMaker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToInboxMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/mail.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Inbox Notes Maker</h2>
      </div>
    </Transition>

    <Transition>
      <div class="stepTwoDialer step" v-show="showDialerMenu">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/call.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Dialer</h2>
        <div class="firstChoiceStruct">
          <div class="inbound-button firstOptions" @click="showDialerInboundSteps">
            <svg class="diffusion-wrapper diffusion-out" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g id="inbound-svg">
                <circle class="diffusion diffusion-one" r="8" cx="12" cy="12" />
                <circle class="diffusion diffusion-two" r="10" cx="12" cy="12"/>
                <circle class="diffusion diffusion-three" r="12" cx="12" cy="12"/>
              </g>
            </svg>
            <div class="overlay"></div>
            <div class="legend">
              <h3>Inbound</h3>
            </div>
          </div>
          <div class="outbound-button firstOptions" @click="showDialerOutboundSteps">
            <svg class="diffusion-wrapper diffusion-out" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g id="outbound-svg">
                <circle class="diffusion diffusion-one" r="8" cx="12" cy="12" />
                <circle class="diffusion diffusion-two" r="10" cx="12" cy="12"/>
                <circle class="diffusion diffusion-three" r="12" cx="12" cy="12"/>
              </g>
            </svg>
            <div class="overlay"></div>
            <div class="legend">
              <h3>Outbound</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeDialerInbound step" v-show="showDialerInbound">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backInboundPrompt">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/call.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Inbound</h2>
        <div class="step-header">
          <span class="step-bullet step-bullet-active"></span>
          <span class="step-bullet" :class="{'step-bullet-active': inboundStepTwoValidated}"></span>
          <span class="step-bullet" :class="{'step-bullet-active': inboundStepThreeValidated}"></span>
          <span class="step-bullet" :class="{'step-bullet-active': inboundStepFourValidated}"></span>
          <span class="step-bullet" :class="{'step-bullet-active': inboundStepFiveValidated}"></span>
        </div>
        <div class="counter-wrapper">
          <h3>{{counterMinute}}:{{counterSecond}}</h3>
        </div>

        <div class="prompt-wrapper" @click="validateCurrentStep">
          <div class="validate-step">
            <svg class="m-check-mark" width="25pt" height="25pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g class="Page-1" stroke="none" stroke-width="20" fill="none" fill-rule="evenodd" stroke-opacity="1">
                <polyline class="checkmark" :class="{ 'checkmark-active': currentStepValidated }" stroke="#ffffff" stroke-width="30" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
              </g>
            </svg>
          </div>
          <h3>"{{currentPrompt}}"</h3>
        </div>

        <div class="next-button" :class="{'next-button-validated': currentStepValidated}" @click="nextInboundPrompt">
          <h3>Next</h3>
        </div>

        <div class="hung-up-button" @click="backToDialerMenu()">
          <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/cross.svg">
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeDialerInbound step" v-show="showDialerOutbound">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backOutboundPrompt">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/call.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Outbound</h2>
        <div class="step-header">
          <span class="step-bullet step-bullet-active"></span>
          <span class="step-bullet" :class="{'step-bullet-active': outboundStepTwoValidated}"></span>
          <span class="step-bullet" :class="{'step-bullet-active': outboundStepThreeValidated}"></span>
        </div>

        <div class="counter-wrapper">
          <h3>{{counterMinute}}:{{counterSecond}}</h3>
<!--          <h3 v-else>{{counterMinute}}:{{counterSecond}}</h3>-->
        </div>

        <div class="prompt-wrapper" @click="validateCurrentStep">
          <div class="validate-step">
            <svg class="m-check-mark" width="25pt" height="25pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g class="Page-1" stroke="none" stroke-width="20" fill="none" fill-rule="evenodd" stroke-opacity="1">
                <polyline class="checkmark" :class="{ 'checkmark-active': currentStepValidated }" stroke="#ffffff" stroke-width="30" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
              </g>
            </svg>
          </div>
          <h3>"{{currentPrompt}}"</h3>
        </div>

        <div class="next-button" :class="{'next-button-validated': currentStepValidated}" @click="nextOutboundPrompt">
          <h3>Next</h3>
        </div>

        <div class="hung-up-button" @click="backToDialerMenu()">
          <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/cross.svg">
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepTwoMyca step" v-show="showMycaMenu">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/myca.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">MYCA</h2>
        <div class="firstChoiceStruct" style="margin-top: 0; flex-direction: column; gap: 0">
          <div class="option button-banner" @click="showMycaTrackerModule">
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>MYCA Notes Tracker</h3>
            </div>
          </div>
          <div class="option button-banner" @click="showMycaNotesMakerModule">
            <div class="option-background website-option"></div>
            <div class="option-overlay"></div>
            <div class="option-title">
              <h3>MYCA Notes Maker</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeMycaTracker step" v-show="showMycaTracker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMycaMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/myca.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Myca Tracker</h2>
        <p style="font-weight: bold; font-size: 12px; margin: 10px 10px 0;">{{appCompleted}}/{{numberOfAppsToComplete}}</p>
        <div class="app-to-complete-header">
          <span v-for="(app, index) in numberOfAppsToComplete" class="app-bullet" :class="{'app-bullet-active': index < appCompleted}" :key="app.id">
            <p style="display: none">{{app}}</p>
          </span>
        </div>
        <div class="tracker-wrapper">
          <img alt="Vue logo" class="less-button-icon" style="margin-top: 20px;" src="../../assets/img/less.svg" @click="lessTime">
          <svg class="tracker-prog" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="tracker-svg">
              <circle class="progression" r="8" cx="12" cy="12"/>
            </g>
          </svg>
          <img alt="Vue logo" class="plus-button-icon" style="margin-top: 20px;" src="../../assets/img/plus.svg" @click="moreTime">
          <div class="set-end-time" v-if="!countdownStarted">
            <!--            <p v-if="endHour>9">Until {{endHour}}</p>-->
            <!--            <p v-if="endHour<10">Until 0{{endHour}}</p>-->
            <p v-if="endHour>9 && endMinute>9">Ends at<br>{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour>9 && endMinute<10">Ends at<br>{{endHour}}:0{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute>9">Ends at<br>0{{endHour}}:{{endMinute}}:00</p>
            <p v-if="endHour<10 && endMinute<10">Ends at<br>0{{endHour}}:0{{endMinute}}:00</p>
            <!--            show hours-->
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond>9">Time left: {{countdownHours}}:{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute>9 && countdownSecond<10">Time left: {{countdownHours}}:{{countdownMinute}}:0{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond>9">Time left: {{countdownHours}}:0{{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours>0 && countdownMinute<10 && countdownSecond<10">Time left: {{countdownHours}}:0{{countdownMinute}}:0{{countdownSecond}}</p>
            <!--            show only minutes & seconds-->
            <p v-if="countdownHours<1 && countdownSecond>9">Time left: {{countdownMinute}}:{{countdownSecond}}</p>
            <p v-if="countdownHours<1 && countdownSecond<10">Time left: {{countdownMinute}}:0{{countdownSecond}}</p>
          </div>
          <div class="countdown-wrapper" v-if="countdownStarted">
            <h3 v-if="countdownMinute > 9 && countdownSecond > 9">{{countdownMinute}}:{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute > 9 && countdownSecond < 10">{{countdownMinute}}:0{{countdownSecond}}</h3>
            <h3 v-if="countdownMinute < 10 && countdownSecond < 10">0{{countdownMinute}}:0{{countdownSecond}}</h3>
          </div>
        </div>
        <div class="button-command">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/stop.svg" @click="stopAndReset">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/play.svg" @click="start(countdownMinute)">
          <img alt="Vue logo" class="control-button-icon" src="../../assets/img/pause.svg" @click="pause">
        </div>
        <div class="apps-command">
          <div class="next-app-button" @click="previousApp">
            <h3>Previous</h3>
          </div>
          <div class="next-app-button" @click="nextApp">
            <h3>Next</h3>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div class="stepThreeMycaNoteMaker step" v-show="showMycaNotesMaker">
        <img alt="Vue logo" class="back-button-icon" style="margin-top: 20px;" src="../../assets/img/back-button.svg" @click="backToMycaMenu">
        <img alt="Vue logo" class="menu-icon" style="margin-top: 20px;" src="../../assets/img/myca.svg">
        <h2 style="margin-top: 10px; margin-left: 0;">Myca Notes Maker</h2>

        <div class="questions-group-wrapper">

          <Transition>
            <div class="myca-question-step myca-question-step-one" v-show="mycaNoteMakersStepOne">
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" style="opacity: 0.2" @click="showMycaPreviousStep">
              <div class="myca-questions">
                <div class="note-maker-question-wrapper">
                  <h3>Is the ID document provided compliant ?</h3>
                  <div class="group-choice">
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': idComp === true}" @click="putIDComp">
                    <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                        <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': idComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                      </g>
                    </svg>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': idComp === false && idComp !== null}" @click="putIDNotComp">
                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                      <path class="cross" :class="{'cross-selected': idComp === false && idComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                    </svg>
                  </div>
                  </div>
                </div>
                <div class="note-maker-question-wrapper">
                  <h3>What type of ID document was provided ?</h3>
                  <div class="group-choice">
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.nic === true}" @click="viaNIC">
                      <p>NIC</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.pp === true}" @click="viaPP()">
                      <p>PP</p>
                    </div>
                    <div class="validate-step-visible" :class="{'validate-step-visible-active': idUsed.rp === true}" @click="viaRP()">
                      <p>RP</p>
                    </div>
                  </div>
                </div>
              </div>
              <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/next.svg" @click="showMycaNextStep">
            </div>
          </Transition>

          <Transition>
          <div class="myca-question-step myca-question-step-two" v-show="mycaNoteMakersStepTwo">
            <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/back.svg" @click="showMycaPreviousStep">
            <div class="myca-questions">
              <div class="note-maker-question-wrapper">
                <h3>Is the POA document provided compliant ?</h3>
                <div class="group-choice">
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaComp === true}" @click="putPOAComp">
                    <svg class="m-check-mark" width="20pt" height="20pt" viewBox="0 0 245 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g class="Page-1" stroke="none" stroke-width="40" fill="none" fill-rule="evenodd" stroke-opacity="1">
                        <polyline class="checkmark-visible" :class="{ 'checkmark-visible-active': poaComp === true}" stroke="#ffffff" stroke-width="40" points="45.640625 120.760742 86.253906 164.663086 192.97168 60.11328125"></polyline>
                      </g>
                    </svg>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaComp === false && poaComp !== null}" @click="putPOANotComp">
                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
                      <path class="cross" :class="{'cross-selected': poaComp === false && poaComp !== null}" d="M247.49,210.2c-13.7,12.73-25.21,23.44-37.95,35.29-21.19-21.45-44.02-44.55-67.46-68.26-24.03,24.28-46.54,47.04-69.58,70.32-12.02-12.93-22.72-24.43-34.58-37.18,21.45-21.21,44.55-44.04,69.15-68.35-24.86-23.48-48.13-45.46-71.11-67.17,13.58-12.79,25.02-23.54,38.57-36.29,19.97,21.38,42.15,45.13,65.78,70.44,24.54-25.96,46.52-49.23,69.06-73.08,12.73,13.69,23.42,25.19,36.02,38.74-21.95,20.63-45.64,42.87-70.88,66.6,25.84,24.42,49.13,46.42,72.97,68.94Z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="note-maker-question-wrapper">
                <h3>What type of POA document was provided ?</h3>
                <div class="group-choice-large">
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ub === true}" @click="poaCompViaUB()">
                    <p>UB</p>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.pt === true}" @click="poaCompViaPT()">
                    <p>PT</p>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ts === true}" @click="poaCompViaTS()">
                    <p>TS</p>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.bs === true}" @click="poaCompViaBS()">
                    <p>BS</p>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.ps === true}" @click="poaCompViaPS()">
                    <p>PS</p>
                  </div>
                  <div class="validate-step-visible" :class="{'validate-step-visible-active': poaUsed.rr === true}" @click="poaCompViaRR()">
                    <p>RR</p>
                  </div>
                </div>
              </div>
            </div>
            <img alt="Vue logo" class="note-maker-step-nav-icon" src="../../assets/img/next.svg" @click="showMycaNextStep">
          </div>
          </Transition>

        </div>

        <div class="note-generated-wrapper">
<!--          <h4 class="note-generated">MYCA &#60; </h4>-->
          <h4 class="note-generated" @click="copyNoteGenerated()">{{noteGenerated}}</h4>
          <p>Click the note to copy to clipboard</p>
        </div>

      </div>
    </Transition>

  </div>
</template>

<script>
import callPrompts from '@/data/local/callPrompts.json';

export default {
  name: 'ProcessesView',
  data(){
    return{

      showMenu: true,
      showQuicksignMenu: false,
      showQuicksignTracker: false,
      showQuicksignNotesMaker: false,
      showInboxMenu: false,
      showInboxTracker: false,
      showInboxNotesMaker: false,
      showDialerMenu: false,
      showMycaMenu: false,
      showMycaTracker: false,
      showMycaNotesMaker: false,
      showDialerInbound: false,
      showDialerOutbound: false,
      counterMinute: 0,
      counterSecond: 0,
      totalSecond: 0,
      intervalId: "",
      activityLength: 0,
      appCompleted: 0,
      numberOfAppsToComplete: 6,
      endDate: new Date(Date.now()),
      endHour: 0,
      endMinute:0,
      countdownStarted: false,
      minutesBeforeEnd: 0,
      countdownHours:0,
      countdownMinute: 30,
      countdownSecond: 0,
      currentPrompt:"",
      currentInboundPrompts: callPrompts.inbound,
      currentInboundIndex: 0,
      currentOutboundPrompts: callPrompts.outbound,
      currentOutboundIndex: 0,
      currentStepValidated: false,

      inboundStepIndex: 0,
      inboundStepOneValidated: false,
      inboundStepTwoValidated: false,
      inboundStepThreeValidated: false,
      inboundStepFourValidated: false,
      inboundStepFiveValidated: false,

      outboundStepIndex: 0,
      outboundStepOneValidated: false,
      outboundStepTwoValidated: false,
      outboundStepThreeValidated: false,
      outboundStepFourValidated: false,
      outboundStepFiveValidated: false,

      mycaNoteMakersStepOne: true,
      mycaNoteMakersStepTwo: false,

      nmoNoteMakersStepOne: true,
      nmoNoteMakersStepTwo: false,
      nmoNoteMakersStepThree: false,
      nmoNoteMakersStepFour: false,

      noteGenerated: "",

      idComp: null,
      poaComp: null,
      poiComp: null,
      ribComp: null,
      fmComp: null,

      idUsed: {
        nic: false,
        pp:  false,
        rp:  false
      },
      
      poaUsed:{
        ub: false,
        pt: false,
        ts: false,
        ps: false,
        bs: false,
        rr: false
      },

      poiUsed:{
        ts: false,
        bs: false,
        ps: false
      }

    }
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    }
  },
  mounted() {



  },
  methods: {
    backToMenu(){
      this.showQuicksignMenu = false
      this.showInboxMenu = false
      this.showDialerMenu = false
      this.showMycaMenu = false

      this.delay(600).then(() => this.showMenu = true );

    },
    backToDialerMenu(){

      clearInterval(this.intervalId)

      this.currentInboundIndex = 0
      this.currentOutboundIndex = 0

      this.inboundStepTwoValidated = false
      this.inboundStepThreeValidated = false
      this.inboundStepFourValidated = false
      this.inboundStepFiveValidated = false

      this.outboundStepTwoValidated = false
      this.outboundStepThreeValidated = false

      this.showDialerInbound = false
      this.showDialerOutbound = false

      this.delay(600).then(() => this.showDialerMenu = true );

    },
    backToQuickSignMenu(){
      clearInterval(this.intervalId);
      this.showQuicksignTracker = false;
      this.showQuicksignNotesMaker = false;
      this.delay(600).then(() => this.showQuicksignMenu = true );
    },
    backToInboxMenu(){
      clearInterval(this.intervalId);
      this.showInboxTracker = false;
      this.showInboxNotesMaker = false;
      this.delay(600).then(() => this.showInboxMenu = true );
    },
    backToMycaMenu(){
      clearInterval(this.intervalId);
      this.showMycaTracker = false;
      this.showMycaNotesMaker = false;
      this.delay(600).then(() => {
        this.showMycaMenu = true;
        this.idComp = null;
        this.poaComp = null;
        this.idUsed.nic = null;
        this.idUsed.pp = null;
        this.idUsed.rp = null;
      } );
    },
    showQuicksignProcess(){

      const that = this;

      this.showMenu = false

      this.delay(600).then(() => that.showQuicksignMenu = true );

    },
    showQuicksignTrackerModule(){

      const that = this;

      this.showQuicksignMenu = false

      this.setNewEndDate()

      this.delay(600).then(() => that.showQuicksignTracker = true );

    },
    showQuicksignNotesMakerModule(){

      const that = this;

      this.showQuicksignMenu = false

      this.delay(600).then(() => {
        that.showQuicksignNotesMaker = true
        that.noteGenerated = "QS < "
      } );

    },
    showInboxProcess(){

      const that = this;

      this.showMenu = false

      this.delay(600).then(() => that.showInboxMenu = true );

    },
    showInboxTrackerModule(){

      const that = this;

      this.showInboxMenu = false

      this.setNewEndDate()

      this.delay(600).then(() => that.showInboxTracker = true );

    },
    showInboxNotesMakerModule(){

      const that = this;

      this.showInboxMenu = false

      this.delay(600).then(() => {
        that.showInboxNotesMaker = true
        that.noteGenerated = "IB < "
      } );

    },
    showDialerProcess(){

      const that = this;

      this.showMenu = false

      this.delay(600).then(() => that.showDialerMenu = true );

    },
    showDialerInboundSteps(){

      const that = this;

      this.showDialerMenu = false

      this.counterMinute = 0
      this.counterSecond = 0
      this.totalSecond = 0
      this.intervalId = setInterval(this.setTime, 1000);

      this.currentPrompt = this.currentInboundPrompts.intro

      this.delay(600).then(() => that.showDialerInbound= true );

    },
    showDialerOutboundSteps(){

      const that = this;

      this.showDialerMenu = false

      this.counterMinute = 0
      this.counterSecond = 0
      this.totalSecond = 0
      this.intervalId = setInterval(this.setTime, 1000);

      this.currentPrompt = this.currentOutboundPrompts.intro

      this.delay(600).then(() => that.showDialerOutbound= true );

    },
    showMycaProcess(){

      const that = this;

      this.showMenu = false;

      this.delay(600).then(() => that.showMycaMenu = true );

    },
    showMycaTrackerModule(){

      const that = this;

      this.showMycaMenu = false

      this.setNewEndDate()

      this.delay(600).then(() => that.showMycaTracker = true );

    },
    showMycaNotesMakerModule(){

      const that = this;

      this.showMycaMenu = false

      this.delay(600).then(() => {
        that.showMycaNotesMaker = true
        that.noteGenerated = "MYCA < "
      } );

    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    validateCurrentStep(){
      this.currentStepValidated = !this.currentStepValidated;
    },
    nextInboundPrompt(){
      if(this.currentStepValidated) {
        if (this.currentInboundIndex === 0) {
          this.currentInboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentInboundPrompts.info
          this.inboundStepTwoValidated = true
        } else if (this.currentInboundIndex === 1) {
          this.currentInboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentInboundPrompts.getApplicationNumber
          this.inboundStepThreeValidated = true
        } else if (this.currentInboundIndex === 2) {
          this.currentInboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentInboundPrompts.confirmCMName
          this.inboundStepFourValidated = true
        } else if (this.currentInboundIndex === 3) {
          this.currentInboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentInboundPrompts.identification
          this.inboundStepFiveValidated = true
        }
      }
    },
    backInboundPrompt(){
      if (this.currentInboundIndex === 4) {

        this.currentInboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentInboundPrompts.confirmCMName
        this.inboundStepFiveValidated = false

      } else if (this.currentInboundIndex === 3) {

        this.currentInboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentInboundPrompts.getApplicationNumber
        this.inboundStepFourValidated = false

      } else if (this.currentInboundIndex === 2) {

        this.currentInboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentInboundPrompts.info
        this.inboundStepThreeValidated = false

      } else if(this.currentInboundIndex === 1){

        this.currentInboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentInboundPrompts.intro
        this.inboundStepTwoValidated = false

      } else if(this.currentInboundIndex === 0){
        this.backToDialerMenu()
      }
    },
    nextOutboundPrompt(){
      if(this.currentStepValidated) {
        if (this.currentOutboundIndex === 0) {
          this.currentOutboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentOutboundPrompts.info
          this.outboundStepTwoValidated = true
        } else if (this.currentOutboundIndex === 1) {
          this.currentOutboundIndex++
          this.currentStepValidated = false
          this.currentPrompt = this.currentOutboundPrompts.identification
          this.outboundStepThreeValidated = true
        }
      }
    },
    backOutboundPrompt(){
      if (this.currentOutboundIndex === 2) {

        this.currentOutboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentOutboundPrompts.info
        this.outboundStepThreeValidated = false

      } else if(this.currentOutboundIndex === 1){

        this.currentOutboundIndex--
        this.currentStepValidated = false
        this.currentPrompt = this.currentOutboundPrompts.intro
        this.outboundStepTwoValidated = false

      } else if(this.currentOutboundIndex === 0){
        this.backToDialerMenu()
      }
    },
    // secondsToHms(d) {
    //   d = Number(d);
    //   const h = Math.floor(d / 3600);
    //   const m = Math.floor(d % 3600 / 60);
    //   const s = Math.floor(d % 3600 % 60);
    //
    //   const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    //   const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    //   const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    //   return hDisplay + mDisplay + sDisplay;
    // },
    // secondsToMs(d) {
    //   d = Number(d);
    //
    //   const m = Math.floor(d % 3600 / 60);
    //   const s = Math.floor(d % 3600 % 60);
    //
    //
    //   const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    //   const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    //   return mDisplay + sDisplay;
    // },
    setTime() {
      this.totalSecond++
      this.counterSecond = this.pad(this.totalSecond % 60);
      this.counterMinute = this.pad(parseInt(this.totalSecond / 60));
    },
    pad(val) {
      const valString = val + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    },
    confirmActivityLength(length){
      console.log(length);
    },
    lessTime(){
      if(this.minutesBeforeEnd > 5 && this.appCompleted < this.numberOfAppsToComplete) {
        this.countdownMinute = this.countdownMinute - 5
        this.numberOfAppsToComplete = this.numberOfAppsToComplete - 1

        this.endDate = new Date( this.endDate.getTime() - 5*60000);
        this.endHour = this.endDate.getHours();
        this.endMinute = this.endDate.getMinutes();

        let timeInBetween = (this.endDate - new Date(Date.now())); // milliseconds between now & Christmas

        this.countdownSecond = Math.floor( (timeInBetween/1000) % 60 );
        this.countdownMinute = Math.floor( (timeInBetween/1000/60) % 60 );
        this.countdownHours = Math.floor( (timeInBetween/(1000*60*60)) % 24 );

        this.minutesBeforeEnd = Math.round(Math.abs(this.endDate - new Date(Date.now())) / (60 * 1000))
      }
    },
    moreTime(){
      if(this.minutesBeforeEnd < 236 || this.numberOfAppsToComplete < 48) {

        this.numberOfAppsToComplete = this.numberOfAppsToComplete + 1

        this.endDate = new Date( this.endDate.getTime() + 5*60000);
        this.endHour = this.endDate.getHours();
        this.endMinute = this.endDate.getMinutes();

        let timeInBetween = (this.endDate - new Date(Date.now())); // milliseconds between now & Christmas

        this.countdownSecond = Math.floor( (timeInBetween/1000) % 60 );
        this.countdownMinute = Math.floor( (timeInBetween/1000/60) % 60 );
        this.countdownHours = Math.floor( (timeInBetween/(1000*60*60)) % 24 );

        this.minutesBeforeEnd = Math.round(Math.abs(this.endDate - new Date(Date.now())) / (60 * 1000))

      }
    },
    stopAndReset(){
      // Stop Countdown
      clearInterval(this.intervalId)

      this.endDate = new Date(Date.now());
      this.endDate.setTime(this.endDate.getTime() + (60 * 60 * 1000));
      this.endDate.setMinutes(0);
      this.endDate.setSeconds(0);
      this.endHour = this.endDate.getHours();
      this.endMinute = this.endDate.getMinutes();

      let timeInBetween = (this.endDate - new Date(Date.now())); // milliseconds between now & Christmas

      this.countdownSecond = Math.floor( (timeInBetween/1000) % 60 );
      this.countdownMinute = Math.floor( (timeInBetween/1000/60) % 60 );
      this.countdownHours = Math.floor( (timeInBetween/(1000*60*60)) % 24 );

      this.minutesBeforeEnd = Math.round(Math.abs(this.endDate - new Date(Date.now())) / (60 * 1000))

      this.appCompleted = 0;
      this.numberOfAppsToComplete = (Math.floor(this.countdownMinute/5))+1;

    },
    start(){
      // Start Countdown
      this.intervalId = setInterval(this.updateTimeLeft, 1000);

    },
    pause(){
      // Pause Countdown
      clearInterval(this.intervalId)
    },
    nextApp(){
      if(this.appCompleted !== this.numberOfAppsToComplete) {
        this.appCompleted = this.appCompleted + 1
      }
    },
    previousApp(){
      if(this.appCompleted !== 0) {
        this.appCompleted = this.appCompleted - 1
      }
    },
    setNewEndDate(){
      this.endDate = new Date(Date.now());
      this.endDate.setTime(this.endDate.getTime() + (60 * 60 * 1000));
      this.endDate.setMinutes(0);
      this.endDate.setSeconds(0);
      this.endHour = this.endDate.getHours();

      let timeInBetween = (this.endDate - new Date(Date.now())); // milliseconds between now & Christmas
      // var diffDays = Math.floor(diffMs / 86400000); // days
      // this.countdownHours = Math.floor((timeInBetween % 86400000) / 3600000); // hours
      // this.countdownMinute = Math.round(((timeInBetween % 86400000) % 3600000) / 60000); // minutes

      this.countdownSecond = Math.floor( (timeInBetween/1000) % 60 );
      this.countdownMinute = Math.floor( (timeInBetween/1000/60) % 60 );
      this.countdownHours = Math.floor( (timeInBetween/(1000*60*60)) % 24 );

      this.minutesBeforeEnd = Math.round(Math.abs(this.endDate - new Date(Date.now())) / (60 * 1000))

      this.appCompleted = 0;
      this.numberOfAppsToComplete = (Math.floor(this.countdownMinute/5))+1;

    },
    updateTimeLeft(){

      let timeInBetween = (this.endDate - new Date(Date.now())); // milliseconds between now & Christmas

      this.countdownSecond = Math.floor( (timeInBetween/1000) % 60 );
      this.countdownMinute = Math.floor( (timeInBetween/1000/60) % 60 );
      this.countdownHours = Math.floor( (timeInBetween/(1000*60*60)) % 24 );

      this.minutesBeforeEnd = Math.round(Math.abs(this.endDate - new Date(Date.now())) / (60 * 1000))

    },
    showMycaPreviousStep(){

      if(this.mycaNoteMakersStepTwo){
        this.mycaNoteMakersStepTwo = false

        this.delay(600).then(() => this.mycaNoteMakersStepOne = true );

      }

    },
    showMycaNextStep(){

      if(this.mycaNoteMakersStepOne){
        this.mycaNoteMakersStepOne = false
        this.delay(600).then(() => this.mycaNoteMakersStepTwo = true );
      }

    },
    showNMOPreviousStep(){

      if(this.nmoNoteMakersStepFour){

        this.nmoNoteMakersStepFour = false
        this.delay(600).then(() => this.nmoNoteMakersStepThree = true );

      } else if(this.nmoNoteMakersStepThree){

        this.nmoNoteMakersStepThree = false
        this.delay(600).then(() => this.nmoNoteMakersStepTwo  = true);

      } else if(this.nmoNoteMakersStepTwo){

        this.nmoNoteMakersStepTwo = false
        this.delay(600).then(() => this.nmoNoteMakersStepOne  = true);

      }

    },
    showNMONextStep(){

      if(this.nmoNoteMakersStepOne){

        this.nmoNoteMakersStepOne = false
        this.delay(600).then(() => this.nmoNoteMakersStepTwo = true );

      } else if(this.nmoNoteMakersStepTwo){

        this.nmoNoteMakersStepTwo = false
        this.delay(600).then(() => this.nmoNoteMakersStepThree = true );

      } else if(this.nmoNoteMakersStepThree){

        this.nmoNoteMakersStepThree = false
        this.delay(600).then(() => this.nmoNoteMakersStepFour = true );

      }

    },
    putIDComp(){
      this.idComp = true;
      this.noteGenerated = this.noteGenerated.replace(/ID not comp /g, "ID comp ");

      if(!this.noteGenerated.includes("ID comp ")){
        this.noteGenerated = this.noteGenerated.concat("ID comp ");
      }
    },
    putIDNotComp(){
      this.idComp = false;
      this.noteGenerated = this.noteGenerated.replace(/ID comp /g, "ID not comp ");

      if(!this.noteGenerated.includes("ID not comp ")){
        this.noteGenerated = this.noteGenerated.concat("ID not comp ");
      }
    },
    viaNIC(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")){

        this.idUsed.rp = false;
        this.idUsed.pp = false;
        this.idUsed.nic = true;
        this.noteGenerated = this.noteGenerated.replace(/via PP < /g, "via NIC < ");
        this.noteGenerated = this.noteGenerated.replace(/via RP < /g, "via NIC < ");

        if(!this.noteGenerated.includes("via NIC < ")) {
          this.noteGenerated = this.noteGenerated.concat("via NIC < ");
        }

      }
    },
    viaPP(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")){

        this.idUsed.rp = false;
        this.idUsed.nic = false;
        this.idUsed.pp = true;
        this.noteGenerated = this.noteGenerated.replace(/via NIC < /g, "via PP < ");
        this.noteGenerated = this.noteGenerated.replace(/via RP < /g, "via PP < ");

        if(!this.noteGenerated.includes("via PP < ")) {
          this.noteGenerated = this.noteGenerated.concat("via PP < ");
        }

      }
    },
    viaRP(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")){

        this.idUsed.pp = false;
        this.idUsed.nic = false;
        this.idUsed.rp = true;
        this.noteGenerated = this.noteGenerated.replace(/via PP < /g, "via RP < ");
        this.noteGenerated = this.noteGenerated.replace(/via NIC < /g, "via RP < ");

        if(!this.noteGenerated.includes("via RP < ")) {
          this.noteGenerated = this.noteGenerated.concat("via RP < ");
        }
      }
    },
    putPOAComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          this.poaComp = true;
          this.noteGenerated = this.noteGenerated.replace(/POA not comp /g, "POA comp ");

          if (!this.noteGenerated.includes("POA comp ")) {
            this.noteGenerated = this.noteGenerated.concat("POA comp ");
          }

        }
      }
    },
    putPOANotComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          this.poaComp = false;
          this.noteGenerated = this.noteGenerated.replace(/POA comp /g, "POA not comp ");

          if(!this.noteGenerated.includes("POA not comp ")){
            this.noteGenerated = this.noteGenerated.concat("POA not comp ");
          }

        }
      }

    },
    poaCompViaUB(){

      this.poaUsed.bs = false;
      this.poaUsed.pt = false;
      this.poaUsed.ts = false;
      this.poaUsed.rr = false;
      this.poaUsed.ps = false;
      this.poaUsed.ub = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via UB < ");

          if (!this.noteGenerated.includes("POA comp via UB < ")) {
            this.noteGenerated = this.noteGenerated.concat("via UB < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA not comp via UB < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via UB < ");

          if (!this.noteGenerated.includes("POA not comp via UB < ")) {
            this.noteGenerated = this.noteGenerated.concat("via UB < ");
          }

        }

      }

    },
    poaCompViaPT(){

      this.poaUsed.bs = false;
      this.poaUsed.ub = false;
      this.poaUsed.ts = false;
      this.poaUsed.rr = false;
      this.poaUsed.ps = false;
      this.poaUsed.pt = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

        this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via PT < ");
        this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via PT < ");

        if(!this.noteGenerated.includes("POA comp via PT < ")) {
          this.noteGenerated = this.noteGenerated.concat("via PT < ");
        }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA not comp via PT < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via PT < ");

          if(!this.noteGenerated.includes("POA not comp via PT < ")) {
            this.noteGenerated = this.noteGenerated.concat("via PT < ");
          }

        }

      }

    },
    poaCompViaTS(){

      this.poaUsed.bs = false;
      this.poaUsed.pt = false;
      this.poaUsed.ub = false;
      this.poaUsed.rr = false;
      this.poaUsed.ps = false;
      this.poaUsed.ts = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via TS < ");

          if (!this.noteGenerated.includes("POA comp via TS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via TS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via TS < ");

          if (!this.noteGenerated.includes("POA not comp via TS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via TS < ");
          }

        }

      }

    },
    poaCompViaRR(){

      this.poaUsed.bs = false;
      this.poaUsed.pt = false;
      this.poaUsed.ts = false;
      this.poaUsed.ub = false;
      this.poaUsed.ps = false;
      this.poaUsed.rr = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via RR < ");

          if (!this.noteGenerated.includes("POA comp via RR < ")) {
            this.noteGenerated = this.noteGenerated.concat("via RR < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA not comp via RR < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via RR < ");

          if (!this.noteGenerated.includes("POA not comp via RR < ")) {
            this.noteGenerated = this.noteGenerated.concat("via RR < ");
          }

        }

      }

    },
    poaCompViaBS(){

      this.poaUsed.ub = false;
      this.poaUsed.pt = false;
      this.poaUsed.ts = false;
      this.poaUsed.rr = false;
      this.poaUsed.ps = false;
      this.poaUsed.bs = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via BS < ");

          if (!this.noteGenerated.includes("POA comp via BS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via BS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via BS < ");

          if (!this.noteGenerated.includes("POA not comp via BS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via BS < ");
          }

        }

      }

    },
    poaCompViaPS(){

      this.poaUsed.bs = false;
      this.poaUsed.pt = false;
      this.poaUsed.ts = false;
      this.poaUsed.rr = false;
      this.poaUsed.ub = false;
      this.poaUsed.ps = true;

      if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")){

        if(this.poaComp) {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PS < /g, "POA comp via PS < ");

          if (!this.noteGenerated.includes("POA comp via PS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via PS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POA comp via BS < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PT < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via TS < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via RR < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via UB < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via BS < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via PT < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via TS < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via RR < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA not comp via UB < /g, "POA not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POA comp via PS < /g, "POA not comp via PS < ");

          if (!this.noteGenerated.includes("POA not comp via PS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via PS < ");
          }

        }

      }

    },
    putPOIComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if(this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              this.poiComp = true;
              this.noteGenerated = this.noteGenerated.replace(/POI not comp /g, "POI comp ");

              if (!this.noteGenerated.includes("POI comp ")) {
                this.noteGenerated = this.noteGenerated.concat("POI comp ");
              }

            }

          }

        }
      }
    },
    putPOINotComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if (this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              this.poiComp = false;
              this.noteGenerated = this.noteGenerated.replace(/POI comp /g, "POI not comp ");

              if (!this.noteGenerated.includes("POI not comp ")) {
                this.noteGenerated = this.noteGenerated.concat("POI not comp ");
              }

            }

          }

        }
      }

    },
    poiCompViaPS(){

      this.poiUsed.bs = false;
      this.poiUsed.ts = false;
      this.poiUsed.ps = true;

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){

        if(this.poiComp) {

          this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI comp via PS < ");

          if (!this.noteGenerated.includes("POI comp via PS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via PS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via PS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via PS < ");

          if(!this.noteGenerated.includes("POI not comp via PS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via PS < ");
          }

        }

      }

    },
    poiNotCompViaPS(){

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){


        this.poiUsed.bs = false;
        this.poiUsed.ts = false;
        this.poiUsed.ps = true;

        this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI not comp via PS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI not comp via PS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via PS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via PS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via PS < ");

        if(!this.noteGenerated.includes("POI not comp via PS < ")) {
          this.noteGenerated = this.noteGenerated.concat("via PS < ");
        }

      }

    },
    poiCompViaTS(){

      this.poiUsed.bs = false;
      this.poiUsed.ts = true;
      this.poiUsed.ps = false;

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){

        if(this.poiComp) {

          this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI comp via TS < ");

          if (!this.noteGenerated.includes("POI comp via TS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via TS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via TS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via TS < ");

          if(!this.noteGenerated.includes("POI not comp via TS < ")) {
            this.noteGenerated = this.noteGenerated.concat("POI not comp via TS < ");
          }

        }

      }

    },
    poiNotCompViaTS(){

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){


        this.poiUsed.bs = false;
        this.poiUsed.ts = true;
        this.poiUsed.ps = false;

        this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI not comp via TS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI not comp via TS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via TS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via TS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via TS < ");

        if(!this.noteGenerated.includes("POI not comp via TS < ")) {
          this.noteGenerated = this.noteGenerated.concat("POI not comp via TS < ");
        }

      }

    },
    poiCompViaBS(){

      this.poiUsed.bs = true;
      this.poiUsed.ts = false;
      this.poiUsed.ps = false;

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){

        if(this.poiComp) {

          this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via BS < /g, "POI comp via BS < ");

          if (!this.noteGenerated.includes("POI comp via BS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via BS < ");
          }

        } else {

          this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via BS < ");
          this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via BS < ");

          if(!this.noteGenerated.includes("POI not comp via BS < ")) {
            this.noteGenerated = this.noteGenerated.concat("via BS < ");
          }

        }

      }

    },
    poiNotCompViaBS(){

      if(this.noteGenerated.includes("POI comp ") || this.noteGenerated.includes("POI not comp ")){


        this.poiUsed.bs = true;
        this.poiUsed.ts = false;
        this.poiUsed.ps = false;

        this.noteGenerated = this.noteGenerated.replace(/POI not comp via PS < /g, "POI not comp via BS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI not comp via TS < /g, "POI not comp via BS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via PS < /g, "POI not comp via BS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via TS < /g, "POI not comp via BS < ");
        this.noteGenerated = this.noteGenerated.replace(/POI comp via BS < /g, "POI not comp via BS < ");

        if(!this.noteGenerated.includes("POI not comp via BS < ")) {
          this.noteGenerated = this.noteGenerated.concat("via BS < ");
        }

      }

    },
    putRIBComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if(this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              if(this.noteGenerated.includes("POI comp via TS") || this.noteGenerated.includes("POI comp via PS") || this.noteGenerated.includes("POI comp via BS") || this.noteGenerated.includes("POI not comp via TS") || this.noteGenerated.includes("POI not comp via PS") || this.noteGenerated.includes("POI not comp via BS")) {

                  this.ribComp = true;
                  this.noteGenerated = this.noteGenerated.replace(/RIB not comp < /g, "RIB comp < ");

                  if (!this.noteGenerated.includes("RIB comp < ")) {
                    this.noteGenerated = this.noteGenerated.concat("RIB comp < ");
                  }

              }

            }

          }

        }
      }
    },
    putRIBNotComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if(this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              if(this.noteGenerated.includes("POI comp via TS") || this.noteGenerated.includes("POI comp via PS") || this.noteGenerated.includes("POI comp via BS") || this.noteGenerated.includes("POI not comp via TS") || this.noteGenerated.includes("POI not comp via PS") || this.noteGenerated.includes("POI not comp via BS")) {

                this.ribComp = false;
                this.noteGenerated = this.noteGenerated.replace(/RIB comp < /g, "RIB not comp < ");

                if (!this.noteGenerated.includes("RIB not comp < ")) {
                  this.noteGenerated = this.noteGenerated.concat("RIB not comp < ");
                }

              }

            }

          }

        }
      }
    },
    putFMComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if(this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              if(this.noteGenerated.includes("POI comp via TS") || this.noteGenerated.includes("POI comp via PS") || this.noteGenerated.includes("POI comp via BS") || this.noteGenerated.includes("POI not comp via TS") || this.noteGenerated.includes("POI not comp via PS") || this.noteGenerated.includes("POI not comp via BS")) {

                if(this.noteGenerated.includes("RIB comp ") || this.noteGenerated.includes("RIB not comp ")) {

                  this.fmComp = true;
                  this.noteGenerated = this.noteGenerated.replace(/FM KO/g, "FM OK");

                  if (!this.noteGenerated.includes("FM OK")) {
                    this.noteGenerated = this.noteGenerated.concat("FM OK");
                  }

                }

              }

            }

          }

        }
      }
    },
    putFMNotComp(){

      if(this.noteGenerated.includes("ID comp ") || this.noteGenerated.includes("ID not comp ")) {
        if(this.noteGenerated.includes("via NIC < ") || this.noteGenerated.includes("via PP < ") || this.noteGenerated.includes("via RP < ")) {

          if(this.noteGenerated.includes("POA comp ") || this.noteGenerated.includes("POA not comp ")) {
            if(this.noteGenerated.includes("via UB < ") || this.noteGenerated.includes("via PT < ") || this.noteGenerated.includes("via TS < ") || this.noteGenerated.includes("via BS < ") || this.noteGenerated.includes("via PS < ") || this.noteGenerated.includes("via RR < ")) {

              if(this.noteGenerated.includes("POI comp via TS") || this.noteGenerated.includes("POI comp via PS") || this.noteGenerated.includes("POI comp via BS") || this.noteGenerated.includes("POI not comp via TS") || this.noteGenerated.includes("POI not comp via PS") || this.noteGenerated.includes("POI not comp via BS")) {

                if(this.noteGenerated.includes("RIB comp ") || this.noteGenerated.includes("RIB not comp ")) {

                  this.fmComp = false;
                  this.noteGenerated = this.noteGenerated.replace(/FM OK/g, "FM KO");

                  if (!this.noteGenerated.includes("FM KO")) {
                    this.noteGenerated = this.noteGenerated.concat("FM KO");
                  }

                }

              }

            }

          }

        }
      }
    },
    copyNoteGenerated(){
      navigator.clipboard.writeText(this.noteGenerated);
    }

  }
}
</script>

<style>
.processes{
  margin-top: 120px;
  text-align: center;
  background: #0b1149;
  padding: 20px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  min-height: 490px;
  transition: height 0.5s ease-in-out;
}

.processes h2{
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.processesChoice{
  margin-top: 50px;
  display: grid;
  grid-template-columns: 450px 450px;
  justify-content: center;
  margin-bottom: 20px;
}

.process{
  overflow: hidden;
  border: 1px solid white;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  position: relative;
  /*background: #ffffff;*/
  /*color: #0b1149;*/
}



.process:hover{
  /*-webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);*/
  /*box-shadow: 0 3px 30px rgb(243 243 243 / 30%);*/
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.process-background{
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  filter: blur(3px);
}

.process-overlay{
  position: absolute;
  background-color: #032436;
  opacity: 0.8;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.process-title{
  z-index: 2;
}

.application{
  background-image: url('~@/assets/img/application.jpg');
}

.inbox{
  background-image: url('~@/assets/img/mailing.jpg');
}

.myca{
  background-image: url('~@/assets/img/myca.jpg');
}

.dialer{
  background-image: url('~@/assets/img/dialer.jpg');
}

.step{
  min-height: 450px;
  position: relative;
}

.stepOne{
  /*display: none;*/
}

/*.stepTwoDialer{*/
/*  display: none;*/
/*}*/

/*.stepThreeDialerInbound{*/
/*  display: none;*/
/*}*/

/*<animateTransform id="scaleAnimation" attributeName="transform" begin="0s" type="scale" dur="2s" from="0.8" to="1" repeatCount="indefinite"/>*/

#inbound-svg {
  transform-origin: center;
  -webkit-animation: inboundAnimation 2s infinite;
  -moz-animation: inboundAnimation 2s infinite;
  -o-animation: inboundAnimation 2s infinite;
  animation: inboundAnimation 2s infinite;
}

#outbound-svg {
  transform-origin: center;
  -webkit-animation: outboundAnimation 2s infinite;
  -moz-animation: outboundAnimation 2s infinite;
  -o-animation: outboundAnimation 2s infinite;
  animation: outboundAnimation 2s infinite;
}

.step-header{
  height: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.app-to-complete-header{
  margin-top: 10px;
  height: 20px;
  display: grid;
  grid-template-areas: "a a a a a a a a a a a a";
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.step-bullet{
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  background: white;
  opacity: 0.6;
}

.app-bullet{
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background: white;
  opacity: 0.6;
}

.step-bullet-active, .app-bullet-active{
  background: #0565ba;
  opacity: 1;
}

.prompt-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 20px;
  background: #0565ba;
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.prompt-wrapper:hover{
  /*-webkit-box-shadow: 0 3px 30px #0153884d;*/
  /*box-shadow: 0 3px 30px #0153884d;*/
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: translate(-50%, -50%) scale(1.1);
}

.questions-group-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.note-maker-question-wrapper{
  /*background: #0565ba;*/
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.note-maker-question-wrapper h3 {
  font-size: 14px;
}

.note-maker-question-wrapper:hover, .note-generated:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.note-generated-wrapper{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.note-generated-wrapper p{
  font-size: 12px;
  font-weight: bold;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.note-generated-wrapper p:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.note-generated{
  background: #0565ba;
  padding: 10px 20px;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.validate-step{
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.validate-step-visible{
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  aspect-ratio: 1;
  opacity: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transform: scale(0.9);
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.validate-step-visible-active{
  opacity: 1;
}

.validate-step-visible p {
  font-size: 10px;
  font-weight: bold;
}

.validate-step-visible:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.myca-questions{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.group-choice, .myca-question-step, .nmo-question-step{
  display: flex;
}

.group-choice{
  gap: 5px;
}

.group-choice-large{
  display: grid;
  grid-template-areas: "a a a";
  gap: 5px;
}

.myca-question-step, .nmo-question-step{
  gap: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.checkmark {
  stroke-dasharray: 640;
  stroke-dashoffset: 640;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.checkmark-active{
  stroke-dashoffset: 0;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.back-button-icon{
  margin-top: 20px;
  max-width: 40px;
  position: absolute;
  left: 20px;
  top: 0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.back-button-icon:hover{
  /*-webkit-box-shadow: 0 3px 30px #0153884d;*/
  /*box-shadow: 0 3px 30px #0153884d;*/
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.less-button-icon, .plus-button-icon, .control-button-icon{
  max-width: 40px;
  height: max-content;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.less-button-icon:hover, .plus-button-icon:hover, .control-button-icon:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.button-command{
  display: flex;
  gap: 20px;
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/*.checkmark:active{*/
/*  -webkit-animation: draw-around 3s ease-in-out;*/
/*  -ms-animation: draw-around 3s ease-in-out;*/
/*  animation: draw-around 3s ease-in-out;*/
/*}*/

/*@-ms-keyframes draw-around {*/
/*  0% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  10% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  100% {*/
/*    stroke-dashoffset: 0;*/
/*  }*/
/*}*/
/*@-webkit-keyframes draw-around {*/
/*  0% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  10% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  100% {*/
/*    stroke-dashoffset: 0;*/
/*  }*/
/*}*/
/*@keyframes draw-around {*/
/*  0% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  10% {*/
/*    stroke-dashoffset: 640;*/
/*  }*/
/*  100% {*/
/*    stroke-dashoffset: 0;*/
/*  }*/
/*}*/

/*/////////////////////////////*/

@keyframes outboundAnimation {
  0%   { opacity:1; transform: scale(0.8) }
  100% { opacity:0; transform: scale(1) }
}
@-o-keyframes outboundAnimation{
  0%   { opacity:1; transform: scale(0.8) }
  100% { opacity:0; transform: scale(1) }
}
@-moz-keyframes outboundAnimation{
  0%   { opacity:1; transform: scale(0.8) }
  100% { opacity:0; transform: scale(1) }
}
@-webkit-keyframes outboundAnimation{
  0%   { opacity:1; transform: scale(0.8) }
  100% { opacity:0; transform: scale(1) }
}

@keyframes inboundAnimation {
  0%   { opacity:1; transform: scale(1) }
  100% { opacity:0; transform: scale(0.8) }
}
@-o-keyframes inboundAnimation {
  0%   { opacity:1; transform: scale(1) }
  100% { opacity:0; transform: scale(0.8) }
}
@-moz-keyframes inboundAnimation {
  0%   { opacity:1; transform: scale(1) }
  100% { opacity:0; transform: scale(0.8) }
}
@-webkit-keyframes inboundAnimation {
  0%   { opacity:1; transform: scale(1) }
  100% { opacity:0; transform: scale(0.8) }
}

@media screen and (max-width: 1180px) {
  .processesChoice{
    grid-template-columns: 300px 300px;
  }
  .processes{
    max-width: 90%;
  }
}

@media screen and (max-width: 820px) {
  .processesChoice{
    grid-template-columns: 240px 240px;
  }
  .processes{
    max-width: 80%;
  }
  .process-title{
    font-size: 14px;
  }
  .process{
    height: 50px;
  }
}

@media screen and (max-width: 720px) {
  .processesChoice{
    grid-template-columns: 200px;
  }
  .processes{
    max-width: 80%;
  }
  .process-title{
    font-size: 14px;
  }
  .process{
    height: 30px;
    margin-bottom: 0;
  }
}

.firstChoiceStruct{
  margin-top: 100px;
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
}

.firstOptions{
  position: relative;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 2px solid #0565ba;
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.firstOptions:hover{
  -webkit-box-shadow: 0 3px 30px #0153884d;
  box-shadow: 0 3px 30px #0153884d;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.inbound-button{
  background-image: url('~@/assets/img/incoming-call.jpg');
}

.outbound-button{
  background-image: url('~@/assets/img/diallercall.jpg');
}

.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #032436;
  opacity: 0.6;
  border-radius: 50%;
}

.button-content{
  position: absolute;
  top: 0;
  margin-left: 10px;
  margin-right: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.diffusion{
  fill: transparent;
  stroke: #0565ba;
}

.diffusion-one{
  stroke-width: 0.3px;
}

.diffusion-two{
  stroke-width: 0.1px;
}

.diffusion-three{
  stroke-width: 0.09px;
}

.diffusion-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  transform: translate(-25%, -25%);
}

.link-header{
  display: block;
  width: fit-content;
  margin: 20px auto 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

}

.link-header p{
  font-weight: bold;
  border: 1px solid white;
  background-color: transparent;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.link-header .button__content p::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  transform-origin: bottom center;
  transform: scaleY(0);
  transition: transform .3s ease;
  will-change: transform;
  z-index: -1;
}

.link-header .button__content:hover p:after {
  transform: scaleY(1);
}

.link-header .button__content p span {
  color: white;
}

.link-header .button__content:hover p span {
  color: black;
  transition: all 0.4s ease-in-out;
}

.hung-up-button{
  width: 50px;
  background-color: #a30000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 50px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.hung-up-button:hover{
  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: translate(-50%) scale(1.1);
}

.hung-up-button .menu-icon{
  margin-top: 0 !important;
}

.counter-wrapper{
  margin-top: 20px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.counter-wrapper:hover{
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.next-button{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 20%;
  border: 1px solid white;
  border-radius: 50px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0.2;
}

.next-button-validated{
  opacity: 1;
  cursor: pointer;
}

.next-button-validated:hover{
  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: translate(-50%) scale(1.1);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.apps-command{
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.next-app-button{
  border: 1px solid white;
  border-radius: 50px;
  height: 40px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.next-app-button h3{
  font-size: 14px;
}

.next-app-button:hover{
  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.tracker-prog{
  max-width: 200px;
  max-height: 350px;
  fill: transparent;
  stroke: #0565ba;
}

.progression{
  stroke-width: 0.1px;
}

.tracker-wrapper{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.set-end-time{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.set-end-time p{
  font-size: 12px;
  font-weight: bold;
}

.note-maker-step-nav-icon{
  max-width: 20px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.note-maker-step-nav-icon:hover{
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: scale(1.1);
}

.cross{
  fill:#fff;
  opacity: 0.5;
}

.cross-selected{
  fill: #c50202;
  opacity: 1;
}

.checkmark-visible{
  stroke: #ffffff;
  opacity: 0.5;
}

.checkmark-visible-active{
  stroke: #099866;
  opacity: 1;
}

/*.link-illustration{*/
/*  width: 150px;*/
/*  height: 150px;*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 10px;*/
/*}*/

/*.quick-sign{*/
/*  background-image: url('~@/assets/simulation.jpg');*/
/*}*/

/*.list-links{*/
/*  display: grid;*/
/*  padding: 20px;*/
/*  !*grid-template-areas: "a a a a a a";*!*/
/*  grid-template-columns: max-content max-content max-content max-content max-content;*/
/*  gap: 50px;*/
/*}*/

/*.important-link{*/
/*  max-width: 150px;*/
/*  cursor: pointer;*/
/*  background: #0b1149;*/
/*  padding: 20px;*/
/*  bottom: 0;*/
/*  border-radius: 10px;*/
/*  -webkit-transition: all 0.2s ease-out;*/
/*  -o-transition: all 0.2s ease-out;*/
/*  transition: all 0.2s ease-out;*/
/*}*/

/*.important-link:hover{*/
/*  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);*/
/*  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);*/
/*  bottom: 10px;*/
/*  -webkit-transition: all 0.2s ease-out;*/
/*  -o-transition: all 0.2s ease-out;*/
/*  transition: all 0.2s ease-out;*/
/*  transform: scale(1.1);*/
/*}*/

/*.link-title{*/
/*  margin-top: 15px;*/
/*}*/

/*.link-description{*/
/*  font-size: 15px;*/
/*  color: #95c0e6;*/
/*}*/

</style>