<template>
  <div class="useful-links">
    <h2>Essentials</h2>
    <div class="essentials list-links">
      <div class="important-link" @click="openQuicksign">
        <div class="link-illustration quicksign"></div>
        <h3 class="link-title">Quicksign</h3>
        <p class="link-description">The original platform used to validate prospect official documents.</p>
      </div>
      <div class="important-link" @click="openGO2">
        <div class="link-illustration go-2"></div>
        <h3 class="link-title">GO2</h3>
        <p class="link-description">The platform used to decision applications for card products.</p>
      </div>
      <div class="important-link" @click="openNMO">
        <div class="link-illustration nmo"></div>
        <h3 class="link-title">QS 2.0 (NMO)</h3>
        <p class="link-description">The new platform used to validate prospect official documents.</p>
      </div>
      <div class="important-link" @click="openDocImport">
        <div class="link-illustration docs-import"></div>
        <h3 class="link-title">Docs Import</h3>
        <p class="link-description">The platform used to import prospects & customers documents.</p>
      </div>
      <div class="important-link" @click="openIVU">
        <div class="link-illustration iVu"></div>
        <h3 class="link-title">IVU</h3>
        <p class="link-description">The American Express database used by Global New Accounts System</p>
      </div>
    </div>
    <h2>Support Links</h2>
    <div class="essentials list-links">
      <div class="important-link" @click="openDocChecker">
        <div class="link-illustration doc-checker"></div>
        <h3 class="link-title">Docs Checker</h3>
        <p class="link-description">The solution for checking the world's identity documents</p>
      </div>
      <div class="important-link" @click="openImpotGouv">
        <div class="link-illustration impot-gouv"></div>
        <h3 class="link-title">Impot Gouv</h3>
        <p class="link-description">French Tax Sheet verification service.</p>
      </div>
      <div class="important-link" @click="openOutlook">
        <div class="link-illustration inbox"></div>
        <h3 class="link-title">Inbox</h3>
        <p class="link-description">GNA Customer / Prospect requests & replies.</p>
      </div>
      <div class="important-link" @click="openSharePoint">
        <div class="link-illustration sharepoint"></div>
        <h3 class="link-title">Sharepoint</h3>
        <p class="link-description">Internal shared document folders.</p>
      </div>
      <div class="important-link" @click="openCurrencyConvertor">
        <div class="link-illustration currency"></div>
        <h3 class="link-title">Currency Convertor</h3>
        <p class="link-description">Currency Convertor By Wise.</p>
      </div>
    </div>
    <h2>Others</h2>
    <div class="essentials list-links">
      <div class="important-link" @click="openAmericanExpress">
        <div class="link-illustration amex"></div>
        <h3 class="link-title">American Express</h3>
        <p class="link-description">American Express official website.</p>
      </div>
      <div class="important-link" @click="openTheSquare">
        <div class="link-illustration theSquare"></div>
        <h3 class="link-title">The Square</h3>
        <p class="link-description">American Express intranet.</p>
      </div>
      <div class="important-link" @click="openWebex">
        <div class="link-illustration webex"></div>
        <h3 class="link-title">Webex</h3>
        <p class="link-description">Video meetings & conferences</p>
      </div>
      <div class="important-link" @click="openSlack">
        <div class="link-illustration slack"></div>
        <h3 class="link-title">Slack</h3>
        <p class="link-description">Stay connected to the team.</p>
      </div>
<!--      <div class="important-link">-->
<!--        <div class="link-illustration twitter"></div>-->
<!--        <h3 class="link-title">Twitter</h3>-->
<!--        <p class="link-description">The original plateform for customer documents checking</p>-->
<!--      </div>-->
    </div>
    <Modal :modalActive="modalActive" @close="closeModal">

      <div class="modal-content">

        <img alt="Vue logo" class="warning-icon" src="../../assets/img/warning.svg">

        <h2 class="modal-title">Access Denied</h2>

      </div>

    </Modal>
  </div>
</template>

<script>
import Modal from "@/core/components/Modal";

export default {
  name: 'HomeView',
  components: {
    Modal,
  },
  data(){
    return{
      modalTitle: "",
      modalActive: false,
    }
  },
  methods: {
    openQuicksign(){
      window.open("https://www.quicksign.fr/CONNECT_BO/portail.do", "_blank")
    },
    openGO2(){
      this.showModal()
    },
    openNMO(){
      window.open("https://keycloak.platform.quicksign.io/auth/realms/amex/protocol/openid-connect/auth?client")
    },
    openDocImport(){
      window.open("https://servicing.aexp.com/communication/docimport", "_blank");
    },
    openIVU(){
      this.showModal()
    },
    openAmericanExpress(){
      window.open("https://www.americanexpress.com/fr-fr/", "_blank");
    },
    openDocChecker(){
      window.open("https://www.documentchecker.com/rdo.dll", "_blank");
    },
    openImpotGouv(){
      window.open("https://cfsmsp.impots.gouv.fr/secavis/", "_blank");
    },
    openSharePoint(){
      window.open("https://spaces.aexp.com/teams/fra_acq/SitePages/Home.aspx?RootFolder=%2Fteams%2Ffra%[…]nitialTabId=Ribbon%2ERead&VisibilityContext=WSSTabPersistence", "_blank");
    },
    openOutlook(){
      window.open("https://outlook.live.com/mail/0/inbox", "_blank");
    },
    openTheSquare(){
      window.open("https://square.americanexpress.com/news", "_blank");
    },
    openWebex(){
      window.open("https://amex.webex.com/", "_blank");
    },
    openSlack(){
      window.open("https://aexp.enterprise.slack.com/", "_blank");
    },
    openCurrencyConvertor(){
      window.open("https://wise.com/gb/currency-converter/", "_blank");
    },
    showModal(){

      if(!this.modalActive){
        this.modalActive = true;
      }

    },
    closeModal(){

      if(this.modalActive){
        this.modalActive = false;
      }

    },
  }
}
</script>

<style>
.useful-links{
  margin-top: 100px;
  text-align: left;
}

.useful-links h2{
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.link-illustration{
  width: 150px;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.quicksign{
  background-image: url('~@/assets/img/quicksign.jpg');
}

.nmo{
  background-image: url('~@/assets/img/nmo.jpg');
}

.go-2{
  background-image: url('~@/assets/img/go-two.jpg');
}

.docs-import{
  background-image: url('~@/assets/img/docimport.jpg');
}

.iVu{
  background-image: url('~@/assets/img/IVU.jpg');
}

.doc-checker{
  background-image: url('~@/assets/img/docchecker.jpg');
}

.impot-gouv{
  background-image: url('~@/assets/img/impotgouv.jpg');
}

.sharepoint{
  background-image: url('~@/assets/img/sharepoint.jpg');
}

.amex{
  background-image: url('~@/assets/img/amexcardphoto.jpg');
}

.theSquare{
  background-image: url('~@/assets/img/thesquare.jpg');
}

.webex{
  background-image: url('~@/assets/img/webex.jpg');
}

.slack{
  background-image: url('~@/assets/img/teams.jpg');
}

.currency{
  background-image: url('~@/assets/img/currencyconverter.jpg');
}

.list-links{
  display: grid;
  padding: 20px;
  /*grid-template-areas: "a a a a a a";*/
  grid-template-columns: max-content max-content max-content max-content max-content;
  gap: 20px;
}

.important-link{
  max-width: 150px;
  cursor: pointer;
  background: #0b1149;
  padding: 20px;
  bottom: 0;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.important-link:hover{
  -webkit-box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  box-shadow: 0 3px 30px rgb(243 243 243 / 30%);
  bottom: 10px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.link-title{
  margin-top: 15px;
}

.link-description{
  font-size: 15px;
  color: #95c0e6;
}

@media screen and (max-width: 1275px) {
  .list-links{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content max-content max-content;
    gap: 20px;
  }
}

@media screen and (max-width: 1060px) {
  .list-links{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content max-content;
    gap: 20px;
  }
}

@media screen and (max-width: 840px) {
  .list-links{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content;
    gap: 20px;
  }
}

@media screen and (max-width: 620px) {
  .list-links{
    padding: 20px;
    /*grid-template-areas: "a a a a a a";*/
    grid-template-columns: max-content max-content;
    gap: 20px;
  }
  .important-link{
    max-width: 90px;
  }
  .link-illustration{
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 500px) {
  .list-links{
    grid-template-columns: 1fr 1fr;
  }
  .important-link{
    max-width: none;
  }
  .link-illustration{
    width: -webkit-fill-available;
    height: auto;
    aspect-ratio: 1;
  }
}

.warning-icon{
  max-width: 150px;
}

.modal-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
}

</style>